import { Box, Image, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { COMMON_WEB_PATH } from "../../../constant";
import demoBg from "../../../images/EventListSelect/demo_item.png";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { formatNumber } from "../../../utils/formatNumber";
import i18next from "i18next";
import { languageMapping } from "../../../i18n/config";
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";
import { find_keyname } from "../utils/find_keyname";
import ItemPrice from "../components/itemPrice";

const MarketBuyItem = (props) => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`market.${key}`, { ...props });

  const navigate = useNavigate();

  const {
    uid,
    product_language,
    stock,
    product_point,
    product_sale_point,
    on_sale,
  } = props || {};

  const product_config = useMemo(() => {
    const product_config = find_keyname({
      list: product_language,
      key: "product_name",
      lng: i18next.language,
    });
    return product_config;
  }, [product_language, i18next.language]);

  return (
    <>
      <Box
        as={Link}
        // to={`/market-store/detail/market-item/1`}
        to={`market-detail/market-item/${uid}`}
        borderRadius={"15px"}
        border="2px solid"
        borderColor="brand.200"
        role="group"
        bgGradient={"linear(to-b,brand.400,brand.700)"}
        _hover={{
          bgGradient: "linear(to-b,brand.500,brand.800)",
        }}
        transition={"all 0.3s"}
        h="150px"
        display="flex"
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"space-between"}
        overflow="hidden"
        position="relative"
      >
        <Box
          borderRadius={"30px"}
          py="2.5px"
          px="5px"
          fontWeight={"bold"}
          bg="green.500"
          position="absolute"
          top="5px"
          right="5px"
          fontSize="8px"
          zIndex="1"
        >
          {i18n("stock")}：{stock || 0}
        </Box>
        <Box mt="20px">
          <Image
            _groupHover={{
              transform: "scale(1.1)",
            }}
            transition={"all 0.3s"}
            borderRadius={"5px"}
            src={`${
              process.env.REACT_APP_I18N_PATH
            }/product/product_${uid}_1.png?${dayjs().format("HH:mm")}`}
            minW="55px"
            h="65px"
            objectFit={"cover"}
          />
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          bg="rgba(0,0,0,0.5)"
          w="100%"
          pb="10px"
          pt="5px"
        >
          <Marquee className="w-[95px]" speed={25}>
            <Text className="text-overflow" fontSize="12px" fontWeight={"bold"}>
              {product_config?.product_name}
            </Text>
          </Marquee>

          <Box
            fontSize="9px"
            fontWeight={"bold"}
            display={"flex"}
            gap="2px"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Image
              src={`${COMMON_WEB_PATH}/b69-home/v3-layout-icon/balance_money_icon.svg`}
              w="10px"
              h="10px"
            />
            <ItemPrice
              on_sale={on_sale}
              product_sale_point={product_sale_point}
              product_point={product_point}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MarketBuyItem;
