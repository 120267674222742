import { Flex, Image } from "@chakra-ui/react";
import { COMMON_WEB_PATH } from "../../constant";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

const imgList = [1, 2, 3, 4, 5];

const PromoIcon = ({ containerClass } = {}) => {
  return (
    <Flex
      as={Link}
      to="/promotion"
      flexDirection={"column"}
      bg="rgba(0,0,0,0.6)"
      px="5px"
      py="15px"
      borderRadius={"10px"}
      {...containerClass}
    >
      <Splide
        // extensions={{ AutoScroll }}
        options={{
          type: "loop",
          focus: "center",
          autoScroll: {
            speed: 0.5,
          },
          pagination: false,
          arrows: false,
          drag: "free",
          direction: "ttb",
          autoplay: true,
          perPage: 5,
          // perMove: 1,
          gap: 10,
          fixedWidth: "30px",
          height: "180px",
          dragMinThreshold: {
            mouse: 0,
            touch: 10,
          },
        }}
      >
        {imgList?.map((item) => {
          return (
            <SplideSlide>
              <Image
                w="30px"
                h="30px"
                mb="5px"
                src={`${COMMON_WEB_PATH}/happy_paradise/float_icon/${item}.png`}
              />
            </SplideSlide>
          );
        })}
      </Splide>
    </Flex>
  );
};

export default PromoIcon;
