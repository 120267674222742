import InApp from "detect-inapp";
import { getMobileOperatingSystem } from "../utils/DetectMobileType";
import {
  api,
  eventApi,
  formDataApi,
  giftApi,
  repeatApi,
  transferApi,
  transferRepeatApi,
  userApi,
} from "./baseApi";
import CryptoJS, { MD5 } from "crypto-js";
import { customCryptoPassword } from "../utils/customCryptoPassword";

const device = getMobileOperatingSystem();
// const inapp = new InApp(
//   navigator.userAgent || navigator.vendor || window.opera
// );
// const ISAPP = inapp.isInApp
//   ? "APP"
//   : window.matchMedia("(display-mode: standalone)").matches
//   ? "PWA"
//   : "WEB";
// const DEVICE = `${device} (${ISAPP})`; //設備檢測

export const actionSignIn = ({ account, passwd } = {}) => {
  return api.post("/login", {
    memId: account,
    passwd: CryptoJS.MD5(passwd).toString(),
    country: "TW",
  });
};

export const actionLogout = () => {
  return api.post("logout");
};

export const updateMemberBasicData = ({ paramsData } = {}) => {
  return api.post("/editMembetInfo", { ...paramsData });
};

export const settlePlatformReward = ({ paramsData } = {}) => {
  return api.post("/event/memberEventClearing", { ...paramsData });
};

export const actionSignUp = ({
  memId,
  passwd,
  unlimit_code, // 無限代推廣碼
  email,
  invite, // 推廣碼 (無限代理)
  currency_code, // 幣別
  code, // 驗證碼
} = {}) => {
  // 要傳給後端的有:
  const dataToSend = {
    memId: memId,
    passwd: customCryptoPassword(passwd),
    // true_name: true_name,
    email: email,
    // mobile: mobile,
    currency_code: currency_code,
    code: code,
  };

  // 當unlimit_code有內容時，才加到dataToSend一起傳給後端
  if (unlimit_code) {
    dataToSend.unlimit_code = unlimit_code;
  }

  // 當 invited有內容時，才加到dataToSend一起傳給後端
  if (invite) {
    dataToSend.invite = invite;
  }
  return api.post("/siginUp", dataToSend);
};

// export const actionSignUp = ({
//   //照NewFrontedEndSystem裡面的MemberService.php為準
//   memId,
//   passwd,
//   true_name,
//   messageapp, //通訊軟體
//   unlimit_code, //無現代推廣碼
//   email,
//   mobile,
//   auth_num, //驗證碼
//   invited, //推廣碼 (無限代理)
//   currency_code, //幣別
// } = {}) => {
//   return api.post("/siginUp", {
//     memId: memId,
//     passwd: customCryptoPassword(passwd),
//     true_name: true_name, //真實姓名
//     //messageapp: messageapp,
//     unlimit_code: unlimit_code,
//     email: email,
//     mobile: mobile,
//     //auth_num: auth_num,
//     invited: invited,
//     currency_code: currency_code,
//   });
// };

export const actionGoogleLogin = ({
  user_id, //req
  email, //req
  unlimit_code, //無現代推廣碼
  invited, //推廣碼
  s_auth, //判斷是不是從此網域使用google
  r_auth, //判斷是不是從此網域使用google
  nick_name,
} = {}) => {
  return repeatApi.post("/goGoogle", {
    user_id: user_id,
    email: email,
    unlimit_code: unlimit_code,
    invited: invited,
    s_auth: s_auth,
    r_auth: r_auth,
    nick_name: nick_name,
  });
};

export const actionTelegramLogin = ({
  id, //req
  hash, //req
  first_name, //req
  last_name, //req
  unlimit_code, //無現代推廣碼
  invited, //推廣碼
  s_auth, //判斷是不是從此網域使用google
  r_auth, //判斷是不是從此網域使用google
  nick_name,
} = {}) => {
  return repeatApi.post("/goTelegram", {
    id: id,
    hash: hash,
    last_name: last_name,
    first_name: first_name,
    unlimit_code: unlimit_code,
    invited: invited,
    s_auth: s_auth,
    r_auth: r_auth,
    nick_name: nick_name,
  });
};

export const resetPassword = ({ old_pwd, new_pwd } = {}) => {
  return api.post("/passwordReset", {
    old_pwd: customCryptoPassword(old_pwd),
    new_pwd: customCryptoPassword(new_pwd),
  });
};

export const setWithdrawPassword = ({ new_pwd, confirm_pwd } = {}) => {
  //重設交易密碼
  return api.post("/setWithdrawPassword", {
    newPassword: customCryptoPassword(new_pwd),
    confirmPassword: customCryptoPassword(confirm_pwd),
  });
};

export const resetWithdrawPassword = ({ old_pwd, new_pwd } = {}) => {
  //重設交易密碼
  return api.post("/withdrawPasswordReset", {
    old_pwd: customCryptoPassword(old_pwd),
    new_pwd: customCryptoPassword(new_pwd),
  });
};

export const depositPayment = ({
  deposit_amount,
  method_id,
  currency_code,
  provider_uid,
} = {}) => {
  return api.post("/thirdPartyPayment/deposit", {
    deposit: {
      deposit_amount: deposit_amount,
      method_id: method_id,
      currency_code: currency_code,
      provider_uid: provider_uid,
      provider_transaction_details: "",
    },
  });
};

export const depositVipPayment = ({
  bank_uid,
  amount,
  bank_number_last5,
  postscript,
} = {}) => {
  return api.post("vip/deposit", {
    bank_uid: bank_uid,
    amount: amount,
    bank_number_last5: bank_number_last5,
    postscript: postscript,
  });
};

export const bindCardInformation = ({ formData } = {}) => {
  return formDataApi.post("/bindBankAccount", formData);
};

export const bankWithdraw = ({
  currency_code,
  withdrawal_amount,
  bank_account_number,
  withdrawal_code,
} = {}) => {
  return api.post("/thirdPartyPayment/withdraw", {
    withdraw: {
      currency_code: currency_code,
      withdrawal_amount: Number(withdrawal_amount),
      bank_account_number: bank_account_number,
      withdrawal_code: CryptoJS.MD5(withdrawal_code).toString(),
      payment_type: "ThirdParty",
    },
  });
};

export const transferWalletToGame = ({
  //转账模式平台,主钱包转至游戏钱包
  platformUid,
  platformType,
  depositAmount,
} = {}) => {
  return transferApi.post("/transfersModeMainToGame", {
    platformUid: platformUid,
    platformType: platformType,
    amount: Number(depositAmount),
  });
};

export const actionUseGift = ({ good_uid } = {}) => {
  return giftApi.post("/use", {
    good_uid: good_uid,
  });
};

export const transferGameToWallet = ({
  //转账模式平台,游戏钱包转至主钱包
  platformUid,
  platformType,
  withdrawAmount,
}) => {
  return transferApi.post("/transfersModeGameToMain", {
    platformUid: platformUid,
    platformType: platformType,
    amount: Number(withdrawAmount),
  });
};

export const transferRepeatGameToWallet = ({
  //转账模式平台,游戏钱包转至主钱包
  platformUid,
  platformType,
  withdrawAmount,
}) => {
  return transferRepeatApi.post("/transfersModeGameToMain", {
    platformUid: platformUid,
    platformType: platformType,
    amount: Number(withdrawAmount),
  });
};

export const singleWalletToGame = ({
  //單一錢包模式,主钱包转至游戏钱包
  platformUid,
  platformType,
  depositAmount,
}) => {
  return transferApi.post("/singleModeMainToGame", {
    platformUid: platformUid,
    platformType: platformType,
    amount: Number(depositAmount),
  });
};

export const singleGameToWallet = ({
  //單一錢包模式,游戏钱包转至主钱包
  platformUid,
  platformType,
  depositAmount,
}) => {
  return transferApi.post("/singleModeGameToMain", {
    platformUid: platformUid,
    platformType: platformType,
    amount: Number(depositAmount),
  });
};

export const withdrawCommission = () => {
  return api.post("/withdraw/commission");
}; //取傭金

export const enterPlayGame = ({
  gameUid,
  lang = "viet",
  isMobile = 2,
  gameCode,
  selectedCurrency,
  gameCurrencyNow,
  walletCurrencyNow,
} = {}) => {
  return api.post("/game/go", {
    gameUid: gameUid,
    lang: lang,
    is_mobile: isMobile,
    gameCode: gameCode,
    currency_code: selectedCurrency,
    gameCurrencyNow: gameCurrencyNow,
    walletCurrencyNow: walletCurrencyNow,
  });
};

export const readInbox = ({ inboxUid } = {}) => {
  return api.put(`/notification/${inboxUid}/read`);
};

//註冊驗證機器人
export const verifiedRobotBoy = (recaptchaResponse) => {
  return api.post("/reCaptcha", { "g-recaptcha-response": recaptchaResponse });
};

export const cloudFlareverifiedRobotBoy = ({ cloudFlareToken }) => {
  return api.post("/siteverify", {
    "cf-turnstile-response": cloudFlareToken,
  });
};

//領取禮包
export const receiveGift = ({ good_uid } = {}) => {
  return giftApi.post("/receive", {
    good_uid: good_uid,
  });
};

export const testSocket = () => {
  return userApi.post("/testForBalanceDetect");
};

export const getRouletteTimes = ({ uid } = {}) => {
  return giftApi.post("/receive", {
    good_uid: uid,
  });
};

// //header右上切換幣別
// export const currencyChange = ({ currency_code }) => {
//   return userApi.post("/currencyChange", { currency_code: currency_code });
// };

//是否以法幣顯示/傳出法幣幣別/藏零數錢包開關
export const NowCurrencyChange = ({
  now_currency,
  hide_zero,
  display_cryptoInFiat,
}) => {
  return userApi.post("/sendNowCurrency", {
    now_currency: now_currency,
    hide_zero: hide_zero,
    display_cryptoInFiat: display_cryptoInFiat,
  });
};

export const checkEventStatus = ({ formData } = {}) => {
  return eventApi.post(`/checkMemberEventsStatus`, { ...formData });
};

export const checkEventWagerStatus = ({ formData } = {}) => {
  return eventApi.get(`/checkMemeberEventsWageringRestricted`, {
    params: formData,
  });
};

export const createBankDepositPayment = ({ formData } = {}) => {
  return api.post("companyPayment/deposit", {
    deposit: {
      ...formData,
    },
  });
};

export const pointTransferToMember = ({
  memId,
  currency,
  amount,
  password,
} = {}) => {
  return api.post("/pointsTransaction", {
    to_memId: memId,
    currency,
    point: amount,
    withdraw_password: password,
  });
};

export const createMarketOrder = ({ formData } = {}) => {
  return api.post("/memberProductOrder", {
    ...formData,
  });
};
