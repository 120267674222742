import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  PinInput,
  PinInputField,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { Link, useNavigate, useParams } from "react-router-dom";
import { waitTime } from "../../../utils/waitTime";
import { createMarketOrder } from "../../../api/postApi";
import { useDispatch } from "react-redux";
import { notice } from "../../../redux/action/action";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../../utils/formatNumber";

const MarketConfirmModal = ({
  isOpen,
  onClose,
  orderDetail,
  productDetail,
}) => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`market.${key}`, { ...props });

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { uid } = useParams();

  const [data, setData] = useState({
    count: localStorage.getItem("count") || "",

    recipient: localStorage.getItem("recipient") || "",
    phone: localStorage.getItem("phone") || "",
    email: localStorage.getItem("email") || "",

    withdraw_pwd: "",
  });
  const {
    shipment_type,
    address,
    member_memo,
    store_code,
    store_name,
    store_type,
  } = orderDetail || {};
  const {
    product_config,
    on_sale,
    product_sale_point,
    product_point,
    shipping_fee_required,
    delivery_fee,
    shipping_fee,
  } = productDetail || {};
  const [buttonLoading, setButtonLoading] = useState(false);
  const { count, recipient, phone, email } = data;

  const handleSubmit = async () => {
    await setButtonLoading(true);
    await waitTime(1000);

    const store_detail =
      shipment_type == "delivery"
        ? { address }
        : { store_type, store_code, store_name };

    await createMarketOrder({
      formData: {
        product_uid: uid,
        numbers: Number(count),
        shipment_type: shipment_type,
        member_memo: member_memo,
        recipient: recipient,
        phone: phone,
        email: email,
        shipping_fee: shipping_fee_required
          ? shipment_type == "delivery"
            ? delivery_fee
            : window?.shipping_fee?.[store_type] || 0
          : 0,
        ...store_detail,
        withdraw_pwd: data.withdraw_pwd,
      },
    })
      .then(async (data) => {
        dispatch(
          notice({
            title: i18n("success_hint"),
            content: "",
            type: "success",
          })
        );
        await waitTime(1000);
        await setButtonLoading(false);
        navigate("./../success");
      })
      .catch((err) => {
        setButtonLoading(false);
      });
  };
  return (
    <>
      {" "}
      <Modal isCentered size={"xs"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          py="20px"
          borderBottom={"7px solid"}
          borderColor="#95690E"
          bgGradient={"linear(to-b,#FCC85A,#C28A17)"}
          borderRadius={"15px"}
          position="relative"
        >
          <Box
            zIndex={1}
            position="absolute"
            top="-10px"
            right="-10px"
            onClick={onClose}
            cursor={"pointer"}
          >
            <Box
              w="46px"
              h="46px"
              borderRadius={"full"}
              bgGradient={"linear(to-r,brand.500,brand.900)"}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
                bgGradient={"linear(to-r,red.600,red.700)"}
                borderRadius={"full"}
                border="1px solid"
                borderColor="leaderBoard.v3-border"
              >
                <Icon as={IoClose} color="light.100" fontSize="32px" />
              </Box>
            </Box>
          </Box>
          <ModalBody>
            <Box
              borderRadius={"25px"}
              bg="#FDFCFA"
              borderBottom="5px solid"
              borderColor="#FCC75A"
              px="10px"
              display="flex"
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              gap="10px"
              py="30px"
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <Text fontWeight={900} fontSize="lg" color="black">
                  {i18n("please_check_delivery_information_is_correct")}
                </Text>
                <Text fontSize="xs" color="red">
                  ({i18n("after_submit_order_can_not_modify")})
                </Text>
              </Box>
              <Divider />
              <Box
                display={"flex"}
                alignItems={"flex-start"}
                fontSize="13px"
                w="100%"
                flexDirection={"column"}
              >
                <Text color="black">
                  {i18n("item")}：
                  <Text as="span" fontWeight={"bold"}>
                    {product_config?.product_name}
                  </Text>
                </Text>
                <Text color="black">
                  {i18n("price")}：
                  <Text as="span" fontWeight={"bold"}>
                    {on_sale == 1
                      ? formatNumber(product_sale_point)
                      : formatNumber(product_point)}
                  </Text>
                </Text>
                {shipping_fee_required == 1 ? (
                  <Text color="black">
                    {i18n("this_product_delivery_fee_is", {
                      fee:
                        shipment_type == "delivery"
                          ? delivery_fee
                          : window?.shipping_fee?.[store_type] || 0,
                      symbol: "",
                    })}
                  </Text>
                ) : null}
                <Text color="black">
                  {i18n("recipient")}：
                  <Text as="span" fontWeight={"bold"}>
                    {recipient}
                  </Text>
                </Text>
                <Text color="black">
                  {i18n("phone")}：
                  <Text as="span" fontWeight={"bold"}>
                    {phone}
                  </Text>
                </Text>
                <Text color="black">
                  {i18n("email")}：
                  <Text as="span" fontWeight={"bold"}>
                    {email}
                  </Text>
                </Text>
                <Text color="black">
                  {i18n("delivery_way")}：
                  <Text as="span" fontWeight={"bold"}>
                    {i18n(shipment_type)}
                  </Text>
                </Text>
                {shipment_type == "delivery" ? (
                  <>
                    <Text color="black">
                      {i18n("delivery_address")}：
                      <Text as="span" fontWeight={"bold"}>
                        {address}
                      </Text>
                    </Text>
                  </>
                ) : (
                  <>
                    <Text color="black">
                      {i18n("store_type")}：
                      <Text as="span" fontWeight={"bold"}>
                        {i18n(store_type)}
                      </Text>
                    </Text>
                    <Text color="black">
                      {i18n("store_code")}：
                      <Text as="span" fontWeight={"bold"}>
                        {store_code}
                      </Text>
                    </Text>
                    <Text color="black">
                      {i18n("store_name")}：
                      <Text as="span" fontWeight={"bold"}>
                        {store_name}
                      </Text>
                    </Text>
                  </>
                )}
              </Box>
              <Divider />
              <Flex gap="5px" color="black" direction={"column"}>
                <Text>{i18n("please_input_withdraw_pwd")}</Text>
                <HStack>
                  <PinInput
                    onChange={(value) => {
                      setData({
                        ...data,
                        withdraw_pwd: value,
                      });
                    }}
                  >
                    <PinInputField borderColor="brand.500" />
                    <PinInputField borderColor="brand.500" />
                    <PinInputField borderColor="brand.500" />
                    <PinInputField borderColor="brand.500" />
                  </PinInput>
                </HStack>
              </Flex>
            </Box>
            <AbsoluteCenter top="105%">
              <Button
                variant={"secondBrandPrimary"}
                type="button"
                onClick={handleSubmit}
                borderRadius={"20px"}
                py="25px"
                px="35px"
                fontWeight={"900"}
                fontSize="20px"
                // borderX="2px solid"
                borderTop="2px solid"
                // borderBottom="5px solid"
                borderColor="v3-second-brand.200"
                outline="2px solid"
                outlineColor={"v3-second-brand.900"}
                position="relative"
                overflow="hidden"
                boxShadow="0px 4px 10px black"
                isLoading={buttonLoading}
              >
                <Box
                  bg="v3-second-brand.500"
                  position="absolute"
                  w="120%"
                  h="200%"
                  top="-145%"
                  borderRadius={"full"}
                ></Box>
                <Text position="relative" zIndex={1}>
                  {i18n("confirm_submit_order")}
                </Text>
              </Button>
            </AbsoluteCenter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MarketConfirmModal;
