import React, { useEffect, useState } from "react";
import { getStoreProductTypeList } from "../../../api/getApi";
import i18next from "i18next";
import { languageMapping } from "../../../i18n/config";
import { useTranslation } from "react-i18next";
import { waitTime } from "../../../utils/waitTime";
import { useDispatch } from "react-redux";
import { storeProductTypeList } from "../../../redux/action/market/marketAction";

const useStoreProductType = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`market.${key}`);

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const defaultType = [
    {
      label: i18n("人氣商品"),
      value: "",
      link: "/market-store",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      await setLoading(true);
      await getStoreProductTypeList().then((data) => {
        const process_data = data
          ?.filter((item) => item.status_front == 1)
          ?.map((item) => {
            const parse_product_type_name = JSON.parse(item.product_type_name);
            const find_product_type_name =
              parse_product_type_name.find(
                (item) => item.language === languageMapping(i18next.language)
              ) || parse_product_type_name?.[0];
            return {
              label: find_product_type_name?.product_type_name,
              value: parse_product_type_name?.[0]?.product_type_name?.replace(
                /\s/g,
                ""
              ),
              uid: item.uid,
            };
          });

        dispatch(storeProductTypeList([...defaultType, ...process_data]));
      });
      await waitTime(1000);
      setLoading(false);
    };

    fetchData();
  }, [i18next.language]);
  return { product_type_loading: loading };
};

export default useStoreProductType;
