import { api, giftApi, recordApi, transferApi, userApi } from "./baseApi";

export const toggleFavoriteGame = ({ params, gameUid }) => {
  const { status } = params;
  return userApi.put(`/toggleFavoriteGame/${gameUid}`, {
    status: status,
  });
}; //切换我的最爱状态

export const modifyProductOrder = ({ formData, uid }) => {
  return api.put(`/memberProductOrder/${uid}`, {
    ...formData,
  });
};

export const deleteProductOrder = ({ uid }) => {
  return api.delete(`/memberProductOrder/${uid}`);
};
