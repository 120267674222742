import { RiProfileFill, RiVipCrownFill } from "react-icons/ri";
import {
  AiTwotoneSound,
  AiFillDollarCircle,
  AiOutlineClear,
} from "react-icons/ai";
import { HiOutlineClipboardList } from "react-icons/hi";
import { TbPokerChip, TbMessage, TbMoneybag } from "react-icons/tb";
import { IoGift, IoSettings, IoWallet } from "react-icons/io5";
import { FaAddressCard, FaCalendarAlt, FaHeadphonesAlt } from "react-icons/fa";
import { HiShare } from "react-icons/hi";
import {
  closeColorMode,
  openCache,
  openChat,
  openColorMode,
  openLanguagePop,
} from "../../redux/action/member/memberAction";
import { MdOutlineLanguage } from "react-icons/md";
import { IoIosMoon, IoIosSend } from "react-icons/io";
import ThemeTab from "../../pages/Settings/components/themeTab";
import { useSelector } from "react-redux";
import { PiChartLineUpBold } from "react-icons/pi";
import { useMemo } from "react";
import { BiSolidLockAlt, BiSolidUserCircle } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { WEB_LAYOUT } from "../../constant";
import { BsShieldFillCheck } from "react-icons/bs";

const iconStyle = "text-2xl mr-[10px]";

const isCredit = process.env.REACT_APP_CREDIT == "1";

export const MENU_LIST = ({ className, dispatch }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const i18n_setting = (key) => t(`settings.${key}`);

  const isMemberInfo = useSelector((state) => state.isMemberInfo);

  const withdraw_password_set = useMemo(() => {
    return isMemberInfo?.withdraw_password_set == 1;
  }, [isMemberInfo]);

  const menu = useMemo(() => {
    if (WEB_LAYOUT == "v2" || WEB_LAYOUT == "v3") {
      return {
        basic: [
          {
            icon: IoIosSend,
            text: i18n("send-points"),
            link: "send-points",
            iconColor: "#963EFF",
            auth: true,
            verify: isMemberInfo?.is_turnover != 1,
            hidden: isCredit,
          },
          {
            icon: RiVipCrownFill,
            text: i18n("vip"),
            link: "vip",
            iconColor: "#D6DDFF",
            iconTextColor: "#537CFF",
            auth: true,
          },
          {
            icon: TbMessage,
            text: i18n("inbox"),
            link: "inbox",
            iconColor: "#D6DDFF",
            iconTextColor: "#537CFF",
            auth: true,
          },

          {
            icon: IoGift,
            text: i18n("rewards"),
            link: "rewards",
            iconColor: "#6448FF",
            auth: true,
            // hidden: isCredit,
          },
          {
            icon: BiSolidUserCircle,
            text: i18n_setting("personalInformation"),
            link: "settings/personal-data",
            iconTextColor: "#FBDEDF",
            iconColor: "#F35B79",
            auth: true,
          },
          {
            icon: RiProfileFill,
            text: i18n_setting("bindIdCard"),
            link: "settings/bind-id-card",
            iconColor: "#E2D8FF",
            iconTextColor: "#7964B8",
            auth: true,
          },
          {
            icon: BiSolidLockAlt,
            text: `${
              withdraw_password_set
                ? i18n_setting("reset")
                : i18n_setting("set")
            } ${i18n_setting("resetWithdrawPassword")}`,
            link: "settings/reset-withdraw-password",
            iconColor: "#E2D8FF",
            iconTextColor: "#7964B8",
            auth: true,
          },
          {
            icon: BsShieldFillCheck,
            text: i18n_setting("userSecureVerity"),
            link: "settings/user-secure-verity",
            iconColor: "#E2D8FF",
            iconTextColor: "#7964B8",
            auth: true,
          },
          {
            icon: BiSolidLockAlt,
            text: i18n_setting("resetPassword"),
            link: "settings/reset-password",
            iconColor: "#E2D8FF",
            iconTextColor: "#7964B8",
            auth: true,
          },
        ],
        setting: [
          // {
          //   icon: IoSettings,
          //   text: "Settings",
          //   link: "settings",
          //   iconColor: "#88919A",
          //   auth: true,
          // },
          {
            icon: FaHeadphonesAlt,
            text: i18n("serviceInformation"),
            link: "../service-group",
            func: () => {
              dispatch(openChat());
            },
            iconColor: "#88919A",
            hidden: isCredit,
          },
          {
            icon: AiOutlineClear,
            text: i18n("clearcache"),
            link: "clear-cache",
            func: () => {
              dispatch(openCache());
            },
            iconColor: "#88919A",
          },

          {
            icon: MdOutlineLanguage,
            text: i18n("language"),
            link: "language",
            func: () => {
              dispatch(openLanguagePop());
            },
            border: true,
            iconColor: "#88919A",
          },
          {
            icon: IoIosMoon,
            text: "Color-Mode",
            link: "color-mode",
            func: () => {
              dispatch(openColorMode());
            },
            border: true,
            iconColor: "#88919A",
            hidden: WEB_LAYOUT == "v2" || WEB_LAYOUT == "v3",
          },
        ],
      };
    } else {
      return {
        basic: [
          {
            icon: IoIosSend,
            text: "Send-Points",
            link: "send-points",
            iconColor: "#963EFF",
            auth: true,
            verify: isMemberInfo?.is_turnover != 1,
            hidden: isCredit,
          },
          {
            icon: PiChartLineUpBold,
            text: "Rebate",
            link: "my-rebate",
            iconColor: "#963EFF",
            auth: true,
            hidden: isCredit,
          },
        ],
        message: [
          {
            icon: IoGift,
            text: "Reward",
            link: "rewards",
            iconColor: "#6448FF",
            auth: true,
            // hidden: isCredit,
          },

          {
            icon: TbMessage,
            text: "Inbox",
            link: "inbox",
            iconColor: "#3FAD66",
            auth: true,
          },
        ],
        setting: [
          {
            icon: IoSettings,
            text: "Settings",
            link: "settings",
            iconColor: "#88919A",
            auth: true,
          },
          {
            icon: FaHeadphonesAlt,
            text: "serviceInformation",
            link: "../service-group",
            func: () => {
              dispatch(openChat());
            },
            iconColor: "#88919A",
            hidden: isCredit,
          },
          {
            icon: AiOutlineClear,
            text: "ClearCache",
            link: "clear-cache",
            func: () => {
              dispatch(openCache());
            },
            iconColor: "#88919A",
          },

          {
            icon: MdOutlineLanguage,
            text: "Language",
            link: "language",
            func: () => {
              dispatch(openLanguagePop());
            },
            border: true,
            iconColor: "#88919A",
          },
          {
            icon: IoIosMoon,
            text: "Color-Mode",
            link: "color-mode",
            func: () => {
              dispatch(openColorMode());
            },
            border: true,
            iconColor: "#88919A",
            hidden: WEB_LAYOUT == "v2",
          },
        ],
      };
    }
  }, [isMemberInfo, WEB_LAYOUT]);

  return menu;
};
