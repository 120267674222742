import { Box, Divider } from "@chakra-ui/react";
import React from "react";
import ItemCard from "./itemCard";

const MarketItemCard = (props) => {
  const { store_product_type_list, productList } = props;
  return (
    <>
      <Box
        borderRadius={"15px"}
        border="2px solid"
        borderColor="brand.200"
        bgGradient={"linear(to-b,brand.400,brand.700)"}
        p="7px"
        h="220px"
        display="flex"
        gap="5px"
        flexDirection={"column"}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
        overflowY="scroll"
        className="no-scrollbar"
      >
        {productList?.slice(0, 3)?.map((item, index) => {
          return (
            <>
              <ItemCard {...item} index={index} />
              <Divider my="0px" />
            </>
          );
        })}
      </Box>
    </>
  );
};

export default MarketItemCard;
