import { Box, Image, Skeleton, Text } from "@chakra-ui/react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useRef } from "react";

import topIconBg from "../../../images/EventListSelect/top-bg.svg";
import demoBg from "../../../images/EventListSelect/demo_item.png";
import { COMMON_WEB_PATH } from "../../../constant";
import { Link } from "react-router-dom";
import i18next from "i18next";
import dayjs from "dayjs";
import { formatNumber } from "../../../utils/formatNumber";
import Marquee from "react-fast-marquee";
import { languageMapping } from "../../../i18n/config";
import { useTranslation } from "react-i18next";
import { find_keyname } from "../utils/find_keyname";
import ItemPrice from "../components/itemPrice";
import { LazyLoadImage } from "react-lazy-load-image-component";

const MarketItemList = ({ productList = [], product_type_loading } = {}) => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`market.${key}`, { ...props });

  const splideRef = useRef();
  return (
    <>
      <Splide
        ref={splideRef}
        options={{
          pagination: false,
          arrows: false,
          drag: "free",
          // perPage: 4,
          perMove: 1,
          gap: 10,
          lazyLoad: "nearby",
          fixedWidth: "105px",
          dragMinThreshold: {
            mouse: 0,
            touch: 10,
          },
        }}
      >
        {product_type_loading
          ? Array.from({ length: 10 }, (_, i) => {
              return (
                <SplideSlide>
                  <Skeleton h="120px" borderRadius={"common"} />
                </SplideSlide>
              );
            })
          : productList?.map((item, index) => {
              const {
                uid,
                product_point,
                product_language,
                on_sale,
                product_sale_point,
              } = item || {};
              // const product_config = product_language?.find(
              //   (item) => item.language === languageMapping(i18next.language)
              // )?.product_name
              //   ? product_language?.find(
              //       (item) =>
              //         item.language === languageMapping(i18next.language)
              //     )
              //   : product_language?.[0];

              const product_config = find_keyname({
                list: product_language,
                key: "product_name",
                lng: i18next.language,
              });

              return (
                <SplideSlide>
                  <Box
                    as={Link}
                    // to={`/market-store/detail/market-item/1`}
                    to={`market-detail/market-item/${uid}`}
                    borderRadius={"15px"}
                    border="2px solid"
                    borderColor="brand.200"
                    bgGradient={"linear(to-b,brand.400,brand.700)"}
                    role="group"
                    _groupHover={{
                      bgGradient: "linear(to-b,brand.500,brand.800)",
                    }}
                    transition={"all 0.3s"}
                    p="7px"
                    h="120px"
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Box
                      borderRadius={"10px"}
                      bg="brand.800"
                      _groupHover={{
                        bg: "brand.900",
                      }}
                      w="100%"
                      h="100%"
                      position="relative"
                      py="5px"
                      overflow={"hidden"}
                      _before={{
                        content: "''",
                        position: "absolute",
                        w: "120%",
                        h: "160%",
                        bgGradient: "linear(to-b,#DD3333,#A52626)",
                        transform: "rotate(45deg)",
                        left: "-72px",
                        top: "-60px",
                      }}
                      _after={{
                        content: "''",
                        position: "absolute",
                        top: 0,
                        w: "100%",
                        h: "3px",
                        bg: "black",
                        opacity: "0.2",
                      }}
                    >
                      <Box
                        position="absolute"
                        display="flex"
                        flexDirection={"column"}
                        alignItems={"center"}
                        top="5px"
                        left="5px"
                        zIndex={"2"}
                        gap="0px"
                        id={`top${index + 1}`}
                      >
                        <Text
                          fontSize="10px"
                          fontWeight={"bold"}
                          color="special.gold"
                        >
                          TOP
                        </Text>
                        <Box
                          bgImage={topIconBg}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          w="25px"
                          h="25px"
                          bgPos={"center"}
                          bgSize={"cover"}
                          bgRepeat={"no-repeat"}
                        >
                          <Text fontWeight={"bold"} color="#BD2C2B">
                            {index + 1}
                          </Text>
                        </Box>
                      </Box>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        gap="5px"
                        w="100%"
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        position="relative"
                        zIndex="1"
                      >
                        <Box w="100%" display="flex" alignItems={"flex-end"}>
                          <Image
                            ml="auto"
                            as={LazyLoadImage}
                            src={`${
                              process.env.REACT_APP_I18N_PATH
                            }/product/product_${uid}_1.png?${dayjs().format(
                              "HH:mm"
                            )}`}
                            minW="45px"
                            h="55px"
                            objectFit={"cover"}
                          />
                        </Box>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          gap="0px"
                          px="10px"
                          // overflow={"hidden"}
                        >
                          <Marquee className="w-[95px]" speed={25}>
                            <Text
                              className="text-overflow"
                              fontSize="10px"
                              fontWeight={"bold"}
                            >
                              {product_config?.product_name}
                            </Text>
                          </Marquee>
                          <Box
                            fontSize="9px"
                            fontWeight={"bold"}
                            display={"flex"}
                            gap="2px"
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Image
                              src={`${COMMON_WEB_PATH}/b69-home/v3-layout-icon/balance_money_icon.svg`}
                              w="10px"
                              h="10px"
                            />
                            <ItemPrice
                              on_sale={on_sale}
                              product_sale_point={product_sale_point}
                              product_point={product_point}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </SplideSlide>
              );
            })}
      </Splide>
    </>
  );
};

export default MarketItemList;
