import { Box } from "@chakra-ui/react";
import { Fragment } from "react";
import CarouselV3 from "../../components/Carousel_v3";
import MarqueeListV2 from "../../components/marquee/marqueeList_v2";
import MarketType from "./components/marketType";
import MarketItemList from "./Market/marketItemList";
import MarketItemCardList from "./Market/marketItemCard/marketItemCardList";
import useStoreProduct from "./hooks/useStoreProduct";
import useStoreProductType from "./hooks/useStoreProductType";

const MarketV3 = () => {
  const { store_product_list, store_product_list_loading } = useStoreProduct();

  return (
    <Fragment>
      {/* <Box as="section" pt="home-space" px="common-y">
        <CarouselV3 carouselType="market" />
      </Box>
      <Box as="section" pt="home-space" px="common-y">
        <MarqueeListV2 />
      </Box>
      <Box as="section" pt="home-space" px="common-y">
        <MarketType store_product_type_list={product_type_list} />
      </Box> */}
      <Box as="section" pt="home-space" px="common-y">
        <MarketItemList
          product_type_loading={store_product_list_loading}
          productList={store_product_list}
        />
      </Box>
      <Box as="section" pt="home-space" px="common-y">
        <MarketItemCardList
          productList={store_product_list}
          store_product_list_loading={store_product_list_loading}
        />
      </Box>
    </Fragment>
  );
};

export default MarketV3;
