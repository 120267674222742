import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { Link, useNavigate, useParams } from "react-router-dom";
import { waitTime } from "../../../utils/waitTime";
import { createMarketOrder } from "../../../api/postApi";
import { useDispatch } from "react-redux";
import { notice } from "../../../redux/action/action";
import { useTranslation } from "react-i18next";
import CustomInput from "../../../components/InputComponents/customInput";
import { modifyProductOrder } from "../../../api/putApi";
import { Link as ChakraLink } from "@chakra-ui/react";

const MarketModifyModal = ({ isOpen, onClose, orderDetail, setTrigger }) => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`market.${key}`, { ...props });

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    shipment_type,
    address,
    member_memo,
    recipient,
    phone,
    email,
    uid,
    store_type,
    store_code,
    store_name,
  } = orderDetail || {};

  const [data, setData] = useState({
    recipient: recipient || "",
    phone: phone || "",
    email: email || "",
    member_memo: member_memo || "",
    address: address || "",
    shipment_type: shipment_type || "",
    store_type: store_type || "",
    store_code: store_code || "",
    store_name: store_name || "",
  });

  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSubmit = async () => {
    await setButtonLoading(true);
    await waitTime(1000);
    await modifyProductOrder({
      uid: uid,
      formData: {
        address: data?.address,
        shipment_type: data?.shipment_type,
        member_memo: data?.member_memo,
        recipient: data?.recipient,
        phone: data?.phone,
        email: data?.email,
      },
    })
      .then(async (data) => {
        dispatch(
          notice({
            title: i18n("modify_success_hint"),
            content: "",
            type: "success",
          })
        );
        await waitTime(1000);
        await setButtonLoading(false);
        await setTrigger((prev) => !prev);
      })
      .catch((err) => {
        setButtonLoading(false);
      });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <>
      {" "}
      <Modal isCentered size={"md"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          py="20px"
          borderBottom={"7px solid"}
          borderColor="#95690E"
          bgGradient={"linear(to-b,#FCC85A,#C28A17)"}
          borderRadius={"15px"}
          position="relative"
        >
          <Box
            zIndex={1}
            position="absolute"
            top="-10px"
            right="-10px"
            onClick={onClose}
            cursor={"pointer"}
          >
            <Box
              w="46px"
              h="46px"
              borderRadius={"full"}
              bgGradient={"linear(to-r,brand.500,brand.900)"}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
                bgGradient={"linear(to-r,red.600,red.700)"}
                borderRadius={"full"}
                border="1px solid"
                borderColor="leaderBoard.v3-border"
              >
                <Icon as={IoClose} color="light.100" fontSize="32px" />
              </Box>
            </Box>
          </Box>
          <ModalBody>
            <Box
              borderRadius={"25px"}
              bg="#FDFCFA"
              borderBottom="5px solid"
              borderColor="#FCC75A"
              px="10px"
              display="flex"
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              gap="10px"
              py="30px"
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
                fontWeight={"bold"}
                fontSize="16px"
              >
                <Text color="black">
                  {i18n("modify_your_order_information")}
                </Text>
              </Box>
              <Divider />
              <Box
                display={"flex"}
                alignItems={"flex-start"}
                fontSize="16px"
                w="100%"
                flexDirection={"column"}
                gap="5px"
              >
                <Text w="100%" color="black">
                  <CustomInput
                    props={{
                      type: "text",
                      label: i18n("recipient"),
                      labelProps: {
                        color: "v3-second-brand.500",
                      },
                      required: true,
                      icon: false,
                      name: "recipient",
                      //className
                      variant: "none",
                      bg: "brand.100",
                      color: "v3-second-brand.500",
                      w: "100%",
                      onChange: handleInput,
                      value: data?.recipient,
                    }}
                  />
                </Text>
                <Text w="100%" color="black">
                  <CustomInput
                    props={{
                      type: "text",
                      label: i18n("phone"),
                      labelProps: {
                        color: "v3-second-brand.500",
                      },
                      required: true,
                      icon: false,
                      name: "phone",
                      //className
                      variant: "none",
                      bg: "brand.100",
                      color: "v3-second-brand.500",
                      w: "100%",
                      onChange: handleInput,
                      value: data?.phone,
                    }}
                  />
                </Text>
                <Text w="100%" color="black">
                  <CustomInput
                    props={{
                      type: "text",
                      label: i18n("email"),
                      labelProps: {
                        color: "v3-second-brand.500",
                      },
                      required: true,
                      icon: false,
                      name: "email",
                      //className
                      variant: "none",
                      bg: "brand.100",
                      color: "v3-second-brand.500",
                      w: "100%",
                      onChange: handleInput,
                      value: data?.email,
                    }}
                  />
                </Text>
                <Text w="100%" color="black">
                  <CustomInput
                    props={{
                      type: "text",
                      label: i18n("shipment_type"),
                      labelProps: {
                        color: "v3-second-brand.500",
                      },
                      required: true,
                      icon: false,
                      name: "shipment_type",
                      //className
                      variant: "none",
                      bg: "brand.100",
                      color: "v3-second-brand.500",
                      w: "100%",
                      onChange: handleInput,
                      isDisabled: true,
                      value: i18n(data?.shipment_type),
                    }}
                  />
                </Text>
                {data?.shipment_type === "delivery" ? (
                  <>
                    <Text w="100%" color="black">
                      <CustomInput
                        props={{
                          type: "text",
                          label: i18n("delivery_address"),
                          labelProps: {
                            color: "v3-second-brand.500",
                          },
                          required: true,
                          icon: false,
                          name: "address",
                          //className
                          variant: "none",
                          bg: "brand.100",
                          color: "v3-second-brand.500",
                          w: "100%",
                          onChange: handleInput,
                          value: data?.address,
                        }}
                      />
                    </Text>
                  </>
                ) : (
                  <>
                    <Text w="100%" color="black">
                      <CustomInput
                        props={{
                          type: "text",
                          label: i18n("store_type"),
                          labelProps: {
                            color: "v3-second-brand.500",
                          },
                          required: true,
                          icon: false,
                          name: "store_type",
                          //className
                          variant: "none",
                          bg: "brand.100",
                          color: "v3-second-brand.500",
                          w: "100%",
                          onChange: handleInput,
                          isDisabled: true,
                          value: data?.store_type,
                        }}
                      />
                    </Text>
                    {store_type === "UNIMARTFREEZE" ||
                    store_type === "UNIMART" ? (
                      <ChakraLink
                        color="red.500"
                        fontWeight={900}
                        fontSize="sm"
                        href="https://emap.pcsc.com.tw/"
                        target="_blank"
                      >
                        {i18n("7-11查詢店號")}
                      </ChakraLink>
                    ) : null}
                    <Text w="100%" color="black">
                      <CustomInput
                        props={{
                          type: "text",
                          label: i18n("store_code"),
                          labelProps: {
                            color: "v3-second-brand.500",
                          },
                          required: true,
                          icon: false,
                          name: "store_code",
                          //className
                          variant: "none",
                          bg: "brand.100",
                          color: "v3-second-brand.500",
                          w: "100%",
                          onChange: handleInput,
                          value: data?.store_code,
                        }}
                      />
                    </Text>

                    <Text w="100%" color="black">
                      <CustomInput
                        props={{
                          type: "text",
                          label: i18n("store_name"),
                          labelProps: {
                            color: "v3-second-brand.500",
                          },
                          required: true,
                          icon: false,
                          name: "store_name",
                          //className
                          variant: "none",
                          bg: "brand.100",
                          color: "v3-second-brand.500",
                          w: "100%",
                          onChange: handleInput,
                          value: data?.store_name,
                        }}
                      />
                    </Text>
                  </>
                )}
                <Text w="100%" color="black">
                  <CustomInput
                    props={{
                      type: "textarea",
                      label: i18n("member_memo"),
                      labelProps: {
                        color: "v3-second-brand.500",
                      },
                      required: true,
                      icon: false,
                      name: "member_memo",
                      //className
                      variant: "none",
                      bg: "brand.100",
                      color: "v3-second-brand.500",
                      w: "100%",
                      onChange: handleInput,
                      value: data?.member_memo,
                    }}
                  />
                </Text>
              </Box>
            </Box>
            <AbsoluteCenter top="100%">
              <Button
                variant={"secondBrandPrimary"}
                type="button"
                onClick={handleSubmit}
                borderRadius={"20px"}
                py="25px"
                px="35px"
                fontWeight={"900"}
                fontSize="20px"
                // borderX="2px solid"
                borderTop="2px solid"
                // borderBottom="5px solid"
                borderColor="v3-second-brand.200"
                outline="2px solid"
                outlineColor={"v3-second-brand.900"}
                position="relative"
                overflow="hidden"
                boxShadow="0px 4px 10px black"
                isLoading={buttonLoading}
              >
                <Box
                  bg="v3-second-brand.500"
                  position="absolute"
                  w="120%"
                  h="200%"
                  top="-145%"
                  borderRadius={"full"}
                ></Box>
                <Text position="relative" zIndex={1}>
                  {i18n("submit_order")}
                </Text>
              </Button>
            </AbsoluteCenter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MarketModifyModal;
