import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { formatNumber } from "../../../utils/formatNumber";
import { useTranslation } from "react-i18next";

const ItemPrice = ({
  product_point = 0,
  on_sale = 0,
  product_sale_point,
  saleStyle = {},
} = {}) => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`market.${key}`, { ...props });
  return (
    <Box display={"flex"} flexDirection={"column"} position="relative">
      {on_sale == 1 ? (
        <Text
          position={"absolute"}
          bottom="-10px"
          fontWeight={"bold"}
          style={saleStyle}
        >
          {i18n("point", {
            point: formatNumber(product_sale_point, { point: false }),
          })}
        </Text>
      ) : null}
      <Text as={on_sale == 1 ? "del" : ""}>
        {i18n("point", {
          point: formatNumber(product_point, { point: false }),
        })}
      </Text>
    </Box>
  );
};

export default ItemPrice;
