import { stylesConfig } from "../helpers/stylesConfig";
import { useLocation, useNavigate } from "react-router";
import { COMMON_ALT, COMMON_WEB_PATH } from "../constant";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Container,
  Flex,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { MdOutlineLanguage } from "react-icons/md";
import { openLanguagePop } from "../redux/action/member/memberAction";
import { IoSearch } from "react-icons/io5";
import useCheckLogin from "../hook/useCheckLogin";
import BalanceV2 from "./SignInUp/components/Balance_v2";
import SignInDataV2 from "./SignInUp/SignInData_v3";
import CreditPage from "../utils/credit/credit_page";
import BalanceV3 from "./SignInUp/components/Balance_v3";
import SignInDataV3 from "./SignInUp/SignInData_v3";
import HeaderAppDownload from "../pages/App/HeaderAppDownload";

const isCredit = process.env.REACT_APP_CREDIT == 1;

const HeaderV3 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const i18n_header = (key) => t(`header.${key}`);

  const headerBg = useColorModeValue("light.100", "dark.200");
  const headerBgGradient = useColorModeValue(
    "--header-gradient.light",
    "--header-gradient.dark"
  );
  const signInColor = useColorModeValue("dark.100", "light.100");
  const headerBorder = useColorModeValue("", "dark.200");
  const { colorMode, toggleColorMode } = useColorMode();

  const { isLogin } = useCheckLogin();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation(); //返回一個location對象，其中包含當前URL的信息。它與window.location最大的區別是，它不會引起頁面重新加載。你可以在任何地方使用它，包括React Hooks組件中。

  const isGameBox = useMemo(() => {
    return location.pathname.includes("gamebox");
  }, [location.pathname]);

  const { pathname } = location; //取網址

  return (
    <Box
      width={{
        base: `100%`,
        xl: `1240px`,
      }}
      minHeight={{
        sm: "55px",
        base: "55px",
      }}
      position="sticky"
      flexDirection={"column"}
      mx="auto"
      top="0px"
      // left="0px"
      zIndex="999"
      display="flex"
      alignItems="center"
      justifyContent="space-between"

      // borderColor={headerBorder}
    >
      <HeaderAppDownload />

      <Flex
        // ml={{
        //   base: "0px",
        //   lg: isGameBox ? "0px" : "219px",
        // }}
        bg={headerBg}
        bgGradient="linear(to-r, #B8441C,#EE9A60)"
        style={{
          borderBottomColor: colorMode === "dark" ? "#000000" : "#cbd5e0",
        }}
        alignItems={`center`}
        justifyContent={`space-between`}
        pl={{
          md: "25px",
          base: "10px",
          // lg: isGameBox ? "0px" : "245px",
        }}
        pr={{
          md: "35px",
          base: "20px",
        }}
        width={`100%`}
        // maxWidth={{
        //   sm: "1024px",
        //   lg: "1440px",
        // }}
        height="100%"
        overflowY="hidden"
      >
        {" "}
        <Flex
          // display={{
          //   base: "block",
          //   lg: isGameBox ? "block" : "none",
          // }}
          alignItems={`center`}
          color="light.100"
        >
          <Box>
            <Link to="/">
              <Image //用三元運算 (? :) 去判斷 如果已登入就顯示 stylesConfig.loggedInLogo 圖片，否則顯示 stylesConfig.mainLogo 圖片
                src={stylesConfig.mainLogo}
                cursor={"pointer"}
                mr={{
                  base: "0px",
                  lg: "45px",
                }}
                ml={{
                  base: "0px",
                  lg: "0px",
                }}
                width={
                  isLogin
                    ? {
                        md: "135px",
                        base: "115px",
                      }
                    : {
                        md: "135px",
                        base: "115px",
                      }
                }
                pointerEvents={"all"}
                alt={COMMON_ALT}
              />
            </Link>
          </Box>
        </Flex>
        {/* <Flex
          display={{
            base: "none",
            md: "block",
          }}
          alignItems={`center`}
          color="light.100"
        >
          <Box>
            <Link to="search">
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={IoSearch} color="gray.300" />
                </InputLeftElement>
                <Input
                  readOnly
                  type="text"
                  variant={`brandPrimary`}
                  borderRadius={`full`}
                  width={{
                    md: "150px",
                    lg: "200px",
                    xl: `500px`,
                    "2xl": "700px",
                  }}
                  bg="light.200"
                  placeholder="Search..."
                />
              </InputGroup>
            </Link>
          </Box>
        </Flex> */}
        <Flex alignItems={`center`} gap="15px" justifyContent={`flex-end`}>
          <Box //電腦版
            display={{
              md: "flex",
              base: "none",
            }}
            gap={{
              md: "15px",
              base: "5px",
            }}
            alignItems={`center`}
          >
            {/* <IconCircleWrapper
              divProps={{
                onClick: () => navigate("search"),
              }}
              className={`hidden md:flex min-h-[38px] min-w-[38px]`}
            >
              <BiSearch className="text-xl" />
            </IconCircleWrapper> */}
            {/* <ColorSwitcher
              colorClassName="!text-white dark:!text-base-dark"
              wordHidden
            /> */}
            {/* <IconCircleWrapper
              divProps={{
                onClick: () => dispatch(openLanguagePop()),
              }}
              className={`hidden md:flex min-h-[38px] min-w-[38px]`}
            >
              <MdOutlineLanguage className="text-xl" />
            </IconCircleWrapper> */}
            <Box
              onClick={() => dispatch(openLanguagePop())}
              display={{
                base: "none",
                md: "flex",
              }}
              position="relative"
              cursor="pointer"
            >
              <Icon
                as={MdOutlineLanguage}
                fontSize="23px"
                mb="3px"
                color="brand.300"
              />
            </Box>
            {isLogin ? <BalanceV3 /> : <></>}
          </Box>
          {isLogin ? (
            <Flex gap="25px">
              <Box
                display={{
                  md: "none",
                  base: "flex",
                }}
              >
                <BalanceV3 />
              </Box>
              <SignInDataV3 />
            </Flex>
          ) : (
            <Flex gap="5px">
              <Box
                onClick={() => dispatch(openLanguagePop())}
                display={{
                  base: "flex",
                  md: "none",
                }}
                alignItems={`center`}
                position="relative"
                cursor="pointer"
              >
                <Icon
                  as={MdOutlineLanguage}
                  fontSize="23px"
                  color="brand.300"
                />
              </Box>
              <Link to="signin">
                <Button
                  variant={`secondBrandPrimary`}
                  borderTop="2px solid"
                  borderTopColor="v3-second-brand.400"
                  type="button"
                  // bg={isCredit ? null : "none"}
                  fontWeight={`semibold`}
                  color={signInColor}
                  minWidth={`45px`}
                  size="sm"
                  height="2.5rem"
                  fontSize="sm"
                >
                  {i18n_header("signin")}
                </Button>
              </Link>
              {/* <CreditPage> */}
              {window.open_registration == 1 && (
                <Link to="signup">
                  <Button
                    variant={"brandPrimary"}
                    type="button"
                    fontWeight={`semibold`}
                    minWidth={`45px`}
                    height="2.5rem"
                    fontSize={`sm`}
                    size="sm"
                  >
                    {i18n_header("signup")}
                  </Button>
                </Link>
              )}

              {/* </CreditPage> */}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default HeaderV3;
