import React from "react";
import { useSelector } from "react-redux";
import Marquee from "react-fast-marquee";
import { AiFillSound } from "react-icons/ai";
import { Box, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import useMarqueeList from "../../hook/useMarqueeList";

const MarqueeListV2 = () => {
  const marqueeList = useMarqueeList();

  const bg = useColorModeValue("light.200", "dark.100");
  const color = useColorModeValue("dark.100", "text.100");

  return (
    <Flex
      bg={"v3-second-brand.500"}
      color={color}
      borderRadius={`10px`}
      alignItems={`center`}
      gap={`10px`}
      py="7.5px"
      px="7.5px"
      borderBottom={"2px solid"}
      borderBottomColor={"#ED8901"}
    >
      <Icon as={AiFillSound} color="brand.500" />
      <Marquee className="">
        {marqueeList?.map((item, index) => {
          const { subject, content } = item;
          return (
            <Flex mr="40px" key={`marquee-${index}`}>
              {/* <Text fontSize="sm" fontWeight={`semibold`}>
                {subject}:
              </Text> */}
              <Text color="special.1" fontSize="sm">
                {content}
              </Text>
            </Flex>
          );
        })}
      </Marquee>
    </Flex>
  );
};

export default MarqueeListV2;
