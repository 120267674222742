import {
  animate,
  isValidMotionProp,
  motion,
  useMotionValue,
  useTransform,
} from "framer-motion";
import React, { useEffect, useState } from "react";
import { formatNumber } from "../../../../utils/formatNumber";
import { getJackpot } from "../../../../api/getApi";
import { API_RELOAD } from "../../../../constant";
import { chakra, shouldForwardProp } from "@chakra-ui/react";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const JackpotNumber = ({ style = {} } = {}) => {
  const [number, setNumber] = useState(0);

  const count = useMotionValue(0);
  const rounded = useTransform(count, (value) =>
    formatNumber(value, { point: false })
  );

  useEffect(() => {
    const animation = animate(count, Number(number), {
      duration: 2,

      ease: "easeInOut",
    });

    return animation.stop;
  }, [number]);

  useEffect(() => {
    const fetchDataPeriodically = () => {
      getJackpot().then((data) => {
        const result = data?.data;

        const getFirstJackpot = result[Object?.keys(result)?.[0]];
        const { jackpot } = getFirstJackpot || {};
        setNumber(jackpot);
      });
    };

    const apiIterval = setInterval(() => {
      fetchDataPeriodically();
    }, API_RELOAD);

    fetchDataPeriodically();

    return () => clearInterval(apiIterval);
  }, []);
  return (
    <>
      <ChakraBox fontFamily={'"Oxygen", serif'} {...style}>
        {rounded}
      </ChakraBox>
    </>
  );
};

export default JackpotNumber;
