import { inputAnatomy } from "@chakra-ui/anatomy";
import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const brandPrimary = defineStyle({
  border: "1px solid",
  borderColor: "gray.200",
  background: "white",
  borderRadius: "common",
  color: "black",
  // Let's also provide dark mode alternatives
  _dark: {
    background: "dark.100",
    borderColor: "gray.700",
    color: "white",
  },
  _focus: {
    borderColor: "brand.500",
  },
  _hover: {
    borderColor: "brand.500",
  },
});

export const textareaTheme = defineStyleConfig({
  variants: { brandPrimary },
});
