import { Box } from "@chakra-ui/react";
import React, { Fragment } from "react";
import CarouselV3 from "../../components/Carousel_v3";
import MarqueeListV2 from "../../components/marquee/marqueeList_v2";
import MarketType from "./components/marketType";
import MarketBuyList from "./MarketType/marketBuyList";
import useStoreProduct from "./hooks/useStoreProduct";
import useStoreProductType from "./hooks/useStoreProductType";

const MarketTypeV3 = () => {
  return (
    <>
      {/* <Box as="section" pt="home-space" px="common-y">
        <CarouselV3 carouselType="market" />
      </Box>
      <Box as="section" pt="home-space" px="common-y">
        <MarqueeListV2 />
      </Box>
      <Box as="section" pt="home-space" px="common-y">
        <MarketType store_product_type_list={product_type_list} />
      </Box> */}
      <Box as="section" pt="home-space" px="common-y">
        <MarketBuyList />
      </Box>
    </>
  );
};

export default MarketTypeV3;
