import { Box, Skeleton } from "@chakra-ui/react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import React, { useMemo, useRef } from "react";
import MarketItemCard from "./marketItemCard";
import { useSelector } from "react-redux";

const MarketItemCardList = ({
  productList,
  store_product_list_loading,
} = {}) => {
  const splideRef = useRef();

  const loadingStatus = useSelector((state) => state.isLoaderStatus);
  const isStoreProductTypeList = useSelector(
    (state) => state.isStoreProductTypeList
  );
  const process_product_list = useMemo(() => {
    return productList?.reduce((acc, item) => {
      const product_type = item?.product_type_uid;

      if (!acc[product_type]) {
        acc[product_type] = [];
      }

      acc[product_type].push(item);

      return acc;
    }, {});
  }, [productList]);

  return (
    <>
      <Splide
        ref={splideRef}
        options={{
          pagination: false,
          arrows: false,
          // drag: "free",
          // perPage: 4,
          perMove: 1,
          gap: 10,
          lazyLoad: "nearby",
          fixedWidth: "330px",
          dragMinThreshold: {
            mouse: 0,
            touch: 10,
          },
        }}
      >
        {loadingStatus || store_product_list_loading
          ? Array.from({ length: 3 }, (_, i) => {
              return (
                <SplideSlide>
                  <Skeleton h="220px" borderRadius={"common"} />
                </SplideSlide>
              );
            })
          : Object?.keys(process_product_list).map((item, index) => {
              return (
                <SplideSlide>
                  <MarketItemCard
                    store_product_type_list={isStoreProductTypeList}
                    productList={process_product_list[item]}
                  />
                </SplideSlide>
              );
            })}
      </Splide>
    </>
  );
};

export default MarketItemCardList;
