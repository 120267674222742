import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  InputLeftElement,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Textarea,
} from "@chakra-ui/react";
import React from "react";

const CustomInput = ({ props }) => {
  const { type, icon, label, required, isInvalid, labelProps } = props || {};
  switch (type) {
    case "text":
      return (
        <FormControl isInvalid={isInvalid} isRequired={required}>
          <FormLabel {...labelProps}>{label}</FormLabel>
          <InputGroup>
            {icon && (
              <InputLeftElement pointerEvents="none">{icon}</InputLeftElement>
            )}
            <Input variant={`brandPrimary`} {...props}></Input>
          </InputGroup>
        </FormControl>
      );
    case "number":
      return (
        <FormControl isRequired={required}>
          <FormLabel {...labelProps}>{label}</FormLabel>
          <InputGroup>
            {icon && (
              <InputLeftElement pointerEvents="none">{icon}</InputLeftElement>
            )}
            <NumberInput w="100%" variant={`brandPrimary`} {...props}>
              <NumberInputField pl="47px" {...props?.field} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </InputGroup>
        </FormControl>
      );
    case "select":
      return (
        <FormControl isRequired={required}>
          <FormLabel {...labelProps}>{label}</FormLabel>
          <InputGroup>
            <Select variant={`brandPrimary`} {...props}>
              {props?.options.map((item) => {
                return (
                  <option disabled={item.disabled} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </Select>
          </InputGroup>
        </FormControl>
      );
    case "date":
      return (
        <FormControl isRequired={required}>
          <FormLabel {...labelProps}>{label}</FormLabel>
          <InputGroup>
            {icon && (
              <InputLeftElement pointerEvents="none">{icon}</InputLeftElement>
            )}
            <Input variant={`brandPrimary`} {...props}></Input>
          </InputGroup>
        </FormControl>
      );
    case "textarea":
      return (
        <FormControl isRequired={required}>
          <FormLabel {...labelProps}>{label}</FormLabel>
          <InputGroup>
            <Textarea variant={"brandPrimary"} {...props} />
          </InputGroup>
        </FormControl>
      );
    default:
      return (
        <FormControl isRequired={required}>
          <FormLabel {...labelProps}>{label}</FormLabel>
          <InputGroup>
            {icon && (
              <InputLeftElement pointerEvents="none">{icon}</InputLeftElement>
            )}
            <Input variant={`brandPrimary`} {...props}></Input>
          </InputGroup>
        </FormControl>
      );
  }
};

export default CustomInput;
