import { Grid, Skeleton } from "@chakra-ui/react";
import React from "react";
import MarketBuyItem from "./marketBuyItem";
import useStoreProduct from "../hooks/useStoreProduct";
import NotFound from "../../../components/ProgressBlock/NotFound";

const MarketBuyList = () => {
  const { store_product_list, store_product_list_loading } = useStoreProduct();

  return (
    <>
      {store_product_list_loading ? (
        <>
          <Grid
            templateColumns={{
              base: "repeat(3, minmax(0, 1fr))",
              md: "repeat(4, minmax(0, 1fr))",
              lg: "repeat(7, minmax(0, 1fr))",
              xl: "repeat(7, minmax(0, 1fr))",
              "2xl": "repeat(7, minmax(0, 1fr))",
            }}
            rowGap={"6px"}
            columnGap="8px"
          >
            {Array.from({ length: 10 }, (_, i) => {
              return <Skeleton h="150px" borderRadius={"common"} />;
            })}{" "}
          </Grid>
        </>
      ) : store_product_list?.length == 0 ? (
        <NotFound />
      ) : (
        <Grid
          templateColumns={{
            base: "repeat(3, minmax(0, 1fr))",
            md: "repeat(4, minmax(0, 1fr))",
            lg: "repeat(7, minmax(0, 1fr))",
            xl: "repeat(7, minmax(0, 1fr))",
            "2xl": "repeat(7, minmax(0, 1fr))",
          }}
          rowGap={"6px"}
          columnGap="8px"
        >
          {store_product_list.map((item, index) => {
            return <MarketBuyItem {...item} />;
          })}
        </Grid>
      )}
    </>
  );
};

export default MarketBuyList;
