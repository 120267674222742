import React, { useEffect, useState } from "react";
import { waitTime } from "../../../utils/waitTime";
import { Box, Flex } from "@chakra-ui/react";
import LoadingBetRecord from "../../../components/Loading/LoadingBetRecord";
import NotFound from "../../../components/ProgressBlock/NotFound";
import RecordDashboard from "./recordDashboard";
import { getStoreProductTransaction } from "../../../api/getApi";
import Paginator from "./paginator";
import MarketRecordDashboard from "./marketRecordDashboard";

const MarketTransaction = ({ datePicker } = {}) => {
  const { str_date, end_date } = datePicker || {};

  const [record, setRecord] = useState([]);
  const [pagination, setPagination] = useState({
    current_page: null,
    last_page: null,
    from: null,
    to: null,
    total: null,
  });
  const [apiCalling, setApiCalling] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setApiCalling(true);
      await getStoreProductTransaction({
        paramsData: {
          str_date,
          end_date,
          limit: 25,
          current_page: currentPage,
        },
      }).then((res) => {
        // const { current_page, last_page, from, to, total, data } = res?.data;
        //   setRecord(data?.data?.data);
        // setPagination({
        //   current_page,
        //   last_page,
        //   from,
        //   to,
        //   total,
        // });
        // setRecord(data);
        setRecord(res);
      });
      await waitTime(1000);
      setApiCalling(false);
    };
    fetchData();
  }, [trigger]);

  return (
    <Flex w="100%" direction={`column`} gap={4} spacing={4}>
      <LoadingBetRecord commonLoading={apiCalling}>
        {record?.length === 0 ? (
          <NotFound />
        ) : (
          record?.map((item, index) => {
            return (
              <MarketRecordDashboard
                setTrigger={setTrigger}
                single={index % 2 === 0}
                {...item}
              />
            );
          })
        )}
      </LoadingBetRecord>
      {/* <Box>
        <Paginator
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pagination={pagination}
        />
      </Box> */}
    </Flex>
  );
};

export default MarketTransaction;
