import { Box, Image, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";
import tagIconBg from "../../../../images/EventListSelect/tag-bg.svg";
import { COMMON_WEB_PATH } from "../../../../constant";
import demoBg from "../../../../images/EventListSelect/demo_item2.png";
import { Link } from "react-router-dom";
import { languageMapping } from "../../../../i18n/config";
import i18next from "i18next";
import { formatNumber } from "../../../../utils/formatNumber";
import Marquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";
import { find_keyname } from "../../utils/find_keyname";
import ItemPrice from "../../components/itemPrice";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ItemCard = (props) => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`market.${key}`, { ...props });

  const {
    index,
    product_language,
    image_url,
    product_point,
    uid,
    on_sale,
    product_sale_point,
  } = props;

  const product_lng_config = useMemo(() => {
    const product_config = find_keyname({
      list: product_language,
      key: "product_name",
      lng: i18next.language,
    });
    return product_config;
  }, [product_language, i18next.language]);

  return (
    <Box
      as={Link}
      to={`market-detail/market-item/${uid}`}
      display={"flex"}
      gap="10px"
      justifyContent={"space-between"}
      alignItems={"center"}
      borderRadius={"15px"}
      _hover={{
        bg: "brand.800",
      }}
      p="5px"
      w="100%"
    >
      <Box display={"flex"} maxW="80%" gap="10px" alignItems={"center"}>
        <Box
          bgImage={tagIconBg}
          bgPos={"center"}
          bgRepeat={"no-repeat"}
          bgSize={"cover"}
          fontWeight={"bold"}
          minW="25px"
          minH="25px"
          maxW="25px"
          maxH="25px"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {index + 1}
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap="3px"
          alignItems={"flex-start"}
          overflow={"hidden"}
        >
          <Marquee className="w-[95px]" speed={25}>
            <Text fontSize="13px" className="text-overflow" fontWeight={"bold"}>
              {product_lng_config?.product_name}
            </Text>
          </Marquee>

          <Box
            display={"flex"}
            alignItems={"center"}
            gap="1px"
            borderRadius={"30px"}
            bg="v3-second-brand.700"
            p="3px"
            px="10px"
          >
            <Image
              src={`${COMMON_WEB_PATH}/b69-home/v3-layout-icon/balance_money_icon.svg`}
              w="20px"
              h="20px"
            />
            <Text display={"flex"} fontWeight={"bold"} fontSize="11px">
              {i18n("price")}：
              <ItemPrice
                on_sale={on_sale}
                product_sale_point={product_sale_point}
                product_point={product_point}
              />
            </Text>
          </Box>
          {/* <Text color="v3-second-brand.700" fontSize="12px">
            售價:1,699,900
          </Text> */}
        </Box>
      </Box>
      <Box>
        <Image
          as={LazyLoadImage}
          objectFit={"contain"}
          src={image_url}
          h="50px"
          w="100px"
        />
      </Box>
    </Box>
  );
};

export default ItemCard;
