import React, { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import PageAnimateWrapper from "../../components/Animate/pageAnimateWrapper";
import { Box, Button, Icon, Text } from "@chakra-ui/react";
import CustomInput from "../../components/InputComponents/customInput";
import { RiUserFill } from "react-icons/ri";
import { MdEmail, MdOutlinePhoneIphone } from "react-icons/md";
import {
  marketPhoneExpressForInput,
  phoneExpressForInput,
} from "../../regex_constanst";
import MarketCloseDetailPage from "./components/marketCloseDetailPage";
import MarketDetailWrapper from "./components/marketDetailWrapper";
import { useTranslation } from "react-i18next";

const MarketInformationV3 = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`market.${key}`, { ...props });

  const navigate = useNavigate();

  const parentProps = useOutletContext();
  const { product_detail, loading } = parentProps || {};

  const [data, setData] = useState({
    recipient: localStorage.getItem("recipient") || "",
    phone: localStorage.getItem("phone") || "",
    email: localStorage.getItem("email") || "",
  });
  const { recipient, phone, email } = data;

  const handleSubmit = (e) => {
    e.preventDefault();

    navigate("./../address");
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    localStorage.setItem(name, value);
  };

  return (
    <>
      <MarketDetailWrapper>
        <MarketCloseDetailPage {...product_detail} />

        <Box>
          <PageAnimateWrapper>
            <Box
              display={"flex"}
              flexDirection={"column"}
              py="20px"
              gap="0px"
              px="20px"
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gap="10px"
                mb="10px"
              >
                <Text
                  className="text-overflow"
                  fontSize="20px"
                  fontWeight={"bold"}
                  mb="5px"
                  color="v3-second-brand.500"
                >
                  {i18n("order_information")}
                </Text>
              </Box>{" "}
              <form
                className="flex flex-col gap-[10px]"
                onSubmit={handleSubmit}
              >
                <Box>
                  <CustomInput
                    props={{
                      type: "text",
                      label: i18n("recipient"),
                      labelProps: {
                        color: "v3-second-brand.500",
                      },
                      name: "recipient",
                      required: true,
                      icon: (
                        <Icon color="v3-second-brand.500" as={RiUserFill} />
                      ),
                      placeholder: "王小明",
                      //className
                      variant: "none",
                      bg: "brand.100",
                      color: "v3-second-brand.500",
                      onChange: handleInput,
                      value: recipient,
                    }}
                  />
                </Box>
                <Box>
                  <CustomInput
                    props={{
                      type: "text",
                      label: i18n("phone"),
                      labelProps: {
                        color: "v3-second-brand.500",
                      },
                      required: true,
                      name: "phone",
                      icon: (
                        <Icon
                          color="v3-second-brand.500"
                          as={MdOutlinePhoneIphone}
                        />
                      ),
                      pattern: marketPhoneExpressForInput,
                      title: i18n("phone_format_error"),
                      placeholder: "0912345678",
                      //className
                      variant: "none",
                      bg: "brand.100",
                      color: "v3-second-brand.500",
                      onChange: handleInput,
                      value: phone,
                    }}
                  />
                </Box>
                <Box>
                  <CustomInput
                    props={{
                      type: "email",
                      label: i18n("email"),
                      labelProps: {
                        color: "v3-second-brand.500",
                      },
                      required: true,
                      name: "email",
                      icon: <Icon color="v3-second-brand.500" as={MdEmail} />,
                      //className
                      variant: "none",
                      bg: "brand.100",
                      color: "v3-second-brand.500",
                      onChange: handleInput,
                      value: email,
                    }}
                  />
                </Box>

                <Box
                  color="v3-second-brand.500"
                  display={"flex"}
                  flexDirection={"column"}
                  gap="5px"
                ></Box>
                <Box mt="20px">
                  {/* <Checkbox my="10px" variant={`brandPrimary`}>
                    同意條款說明
                  </Checkbox> */}
                  <Button
                    bg="transparent"
                    type="submit"
                    bgGradient={"linear(to-b,#FC5151,#C91919)"}
                    borderRadius={"30px"}
                    w="100%"
                    size="lg"
                    _hover={{
                      bgGradient: "linear(to-b,#FC5151,#C91919)",
                    }}
                    _active={{
                      bgGradient: "linear(to-b,#FC5151,#C91919)",
                    }}
                  >
                    {i18n("write_delivery_information")}
                  </Button>
                </Box>
              </form>
            </Box>
          </PageAnimateWrapper>
        </Box>
      </MarketDetailWrapper>
    </>
  );
};

export default MarketInformationV3;
