import React, { useMemo, useState } from "react";
import MyBalanceV3 from "../../pages/Profile/components/balance_v3";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import PageAnimateWrapper from "../../components/Animate/pageAnimateWrapper";
import {
  Box,
  Button,
  Image,
  NumberInput,
  NumberInputField,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import MarketCloseDetailPage from "./components/marketCloseDetailPage";
import MarketDetailWrapper from "./components/marketDetailWrapper";
import { formatNumber } from "../../utils/formatNumber";
import { useTranslation } from "react-i18next";
import useMyBalance from "../../hook/useMyBalance";
import PointNotEnoughModal from "./components/pointNotEnoughModal";

const MarketCheckItemV3 = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`market.${key}`, { ...props });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();

  const [data, setData] = useState({
    count: localStorage.getItem("count") || 0,
  });

  const { count } = data;

  const parentProps = useOutletContext();
  const { product_detail, loading } = parentProps || {};

  const my_balance = useMyBalance();

  const {
    img_path,
    product_language,
    stock,
    product_point,
    product_config,
    on_sale,
    product_sale_point,
  } = product_detail || {};

  const item_point = useMemo(() => {
    return on_sale == 1 ? product_sale_point * count : product_point * count;
  }, [product_point, product_sale_point, on_sale, count]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (my_balance < Number(item_point)) {
      onOpen();
    } else {
      navigate("./../information");
    }
  };

  const handleInput = (value) => {
    setData({
      ...data,
      count: value,
    });
    localStorage.setItem("count", value);
  };

  return (
    <>
      <MarketDetailWrapper>
        <MarketCloseDetailPage {...product_detail} />
        <Box>
          <PageAnimateWrapper>
            <Box
              display={"flex"}
              flexDirection={"column"}
              py="30px"
              gap="0px"
              px="20px"
              mb="10px"
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gap="10px"
                mb="10px"
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  borderRadius={"15px"}
                >
                  <Image
                    mx="auto"
                    src={img_path}
                    w="75px"
                    objectFit={"cover"}
                  />
                </Box>
                <Text
                  className="text-overflow"
                  fontSize="20px"
                  fontWeight={"bold"}
                  mb="5px"
                  color="v3-second-brand.500"
                >
                  {product_config?.product_name}
                </Text>
              </Box>
              <form onSubmit={handleSubmit}>
                <Box
                  display={"flex"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  w="100%"
                  color="v3-second-brand.500"
                  py="15px"
                >
                  <Text fontSize="18px" fontWeight={"medium"}>
                    {i18n("exchange_need_point")}
                  </Text>
                  <Text
                    className="text-overflow"
                    fontSize="16px"
                    fontWeight={"bold"}
                    color="red.500"
                  >
                    {formatNumber(
                      on_sale == 1 ? product_sale_point : product_point,
                      { point: false }
                    )}
                  </Text>
                </Box>
                {/* <Box
                display={"flex"}
                alignItems={"flex-end"}
                justifyContent={"space-between"}
                w="100%"
                color="v3-second-brand.500"
                borderTop="2px solid"
                borderColor="#FED580"
                py="15px"
              >
                <Text fontSize="18px" fontWeight={"medium"}>
                  配送方式
                </Text>
                <Select size="sm" w="100px">
                  <option value="宅配">宅配</option>
                  <option value="超商">超商</option>
                </Select>
              </Box> */}
                <Box
                  display={"flex"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  w="100%"
                  color="v3-second-brand.500"
                  borderTop="2px solid"
                  borderColor="#FED580"
                  py="15px"
                >
                  <Text fontSize="18px" fontWeight={"medium"}>
                    {i18n("buy_count")}
                  </Text>
                  <NumberInput
                    value={count}
                    onChange={handleInput}
                    isRequired
                    autoFocus
                    size="sm"
                    min={1}
                    max={stock}
                  >
                    <NumberInputField textAlign={"end"} pr="0.5rem" />
                  </NumberInput>
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  w="100%"
                  color="v3-second-brand.500"
                  borderTop="2px solid"
                  borderColor="#FED580"
                  py="15px"
                >
                  <Text fontSize="18px" fontWeight={"medium"}>
                    {i18n("all_need_point")}
                  </Text>
                  <Box display={"flex"} flexDirection={"column"} gap="3px">
                    <Text
                      className="text-overflow"
                      fontSize="12px"
                      fontWeight={"medium"}
                      color="brand.800"
                      display={"flex"}
                      alignItems={"center"}
                      gap="5px"
                    >
                      {i18n("current_point")}{" "}
                      <MyBalanceV3
                        hiddenSymbol
                        balanceStyle={{
                          fontSize: "12px",
                          fontWeight: "700",
                          color: "brand.800",
                        }}
                      />
                    </Text>
                    <Text
                      className="text-overflow"
                      fontSize="16px"
                      fontWeight={"bold"}
                      color="red.500"
                    >
                      {formatNumber(
                        on_sale == 1
                          ? product_sale_point * count
                          : product_point * count,
                        { point: false }
                      )}
                    </Text>
                  </Box>
                </Box>
                <Box
                  color="v3-second-brand.500"
                  display={"flex"}
                  flexDirection={"column"}
                  gap="5px"
                ></Box>

                <Box mt="20px">
                  {/* <Checkbox my="10px" variant={`brandPrimary`}>
                  同意條款說明
                </Checkbox> */}
                  <Button
                    type="submit"
                    bg="transparent"
                    bgGradient={"linear(to-b,#FC5151,#C91919)"}
                    borderRadius={"30px"}
                    w="100%"
                    size="lg"
                    _hover={{
                      bgGradient: "linear(to-b,#FC5151,#C91919)",
                    }}
                    _active={{
                      bgGradient: "linear(to-b,#FC5151,#C91919)",
                    }}
                  >
                    {i18n("write_personal_information")}
                  </Button>
                </Box>
              </form>
            </Box>
          </PageAnimateWrapper>
        </Box>
      </MarketDetailWrapper>
      <PointNotEnoughModal
        needMoney={Number(item_point) - Number(my_balance)}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export default MarketCheckItemV3;
