import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { languageMapping } from "../i18n/config";
import i18next from "i18next";
import { API_WEB_PATH } from "../constant";

const useProcessCarouselList = ({ carousel_list, carouselType = 1 } = {}) => {
  const process_carousel_list = useMemo(() => {
    return carousel_list
      ?.filter((item) => {
        if (carouselType == 2) {
          return item.type == 2;
        } else {
          return item.type == 1;
        }
      })
      ?.map((item) => {
        const { carousels_lang, uid, subject } = item;

        const find_language =
          carousels_lang?.find((item) => {
            return item.lang === languageMapping(i18next.language);
          }) || carousels_lang[0];

        const process_url_link = find_language?.url_link?.replace(
          window.location.origin,
          ""
        );

        return {
          uid,
          subject,
          ...find_language,
          process_url_link,
        };
      });
  }, [carousel_list, i18next, carouselType]);
  return process_carousel_list;
};

export default useProcessCarouselList;
