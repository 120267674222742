import { Box, Flex, Link, Text } from "@chakra-ui/react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";

const MarketType = ({
  forceShowAll,
  gameMainTypeFromProps,
  store_product_type_list,
}) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`market.${key}`);

  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);

  const splideRef = useRef();

  const { marketType } = useParams();

  useEffect(() => {
    if (splideRef.current) {
      const splide = splideRef.current.splide;
      const mapValue = store_product_type_list?.map((item) => item.value);
      splide.go(mapValue?.indexOf(marketType));
    }
  }, [marketType, store_product_type_list]);

  return (
    <Box
      className="no-scrollbar"
      bg="dark.100"
      minH="35px"
      borderRadius={`30px`}
      overflow="hidden"
      overflowX="scroll"
    >
      <Splide
        ref={splideRef}
        options={{
          pagination: false,
          arrows: false,
          drag: "free",
          // perPage: 4,
          perMove: 1,
          gap: 5,
          lazyLoad: "nearby",
          fixedWidth: "85px",
          dragMinThreshold: {
            mouse: 0,
            touch: 10,
          },
        }}
      >
        {store_product_type_list?.map((item, index) => {
          return (
            <SplideSlide key={item.label}>
              <Link
                as={NavLink}
                to={item.link || `/market-store/detail/${item.value}`}
                _hover={{
                  textDecoration: "none",
                }}
              >
                {({ isActive }) => {
                  const is_active = !marketType
                    ? index === 0
                    : item.value === marketType;

                  return (
                    <Box
                      borderRadius={`30px`}
                      // bgGradient="linear(to-b,  #141614, #2c2b2e, #141614)"
                      // bgGradient={
                      //   isActive || is_default
                      //     ? "linear(to-b, transparent, brand.500 120%)"
                      //     : "linear(to-b, transparent, brand.500 400%)"
                      // }
                      justifyContent={`center`}
                      display="flex"
                      height="35px"
                      // overflow="hidden"
                      bg={is_active ? "brand.500" : ""}
                      // bg={isActive || is_default ? `brand.700` : ``}
                      borderTop={is_active ? `3px solid` : ``}
                      borderColor={is_active ? `brand.700` : ``}
                      position="relative"
                      overflow={`hidden`}
                    >
                      {/* {isActive || is_default ? (
                        <AbsoluteCenter
                          h="120%"
                          borderRadius={"full"}
                          w="110%"
                          top="-5%"
                          bg="brand.500"
                        ></AbsoluteCenter>
                      ) : null} */}
                      <Flex
                        justifyContent="center"
                        minW="100px"
                        maxW="100px"
                        gap="3px"
                        px="15px"
                        alignItems={`center`}
                        borderRadius={`common`}
                        position="relative"
                        zIndex={1}
                        color={is_active ? `white` : `brand.500`}
                        // borderBottom={isActive ? `3px solid` : ``}
                        // borderColor={isActive ? `brand.300` : ``}
                      >
                        <Text
                          className="text-overflow"
                          fontSize="10px"
                          fontWeight="600"
                          letterSpacing={"-0.2px"}
                        >
                          {/* {i18n(item.label)} */}
                          {item.label}
                        </Text>
                      </Flex>
                    </Box>
                  );
                }}
              </Link>
            </SplideSlide>
          );
        })}
      </Splide>
    </Box>
  );
};

export default MarketType;
