import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Text,
  chakra,
  shouldForwardProp,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AiFillDollarCircle } from "react-icons/ai";
import { BiMoneyWithdraw } from "react-icons/bi";
import { BsCashCoin } from "react-icons/bs";
import { FaHistory } from "react-icons/fa";
import { FaPiggyBank } from "react-icons/fa6";
import { MdHistory } from "react-icons/md";
import { TbPigMoney, TbReportAnalytics } from "react-icons/tb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BalanceV2 from "../../Profile/components/balance_v2";
import { PiChartLineUpBold } from "react-icons/pi";
import { gameListFromBottom } from "../../../animateConstant";
import { isValidMotionProp, motion } from "framer-motion";
import BalanceV3 from "../../Profile/components/balance_v3";
import { GoPlus } from "react-icons/go";
import { COMMON_WEB_PATH } from "../../../constant";

const isCredit = process.env.REACT_APP_CREDIT == 1;

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const common_tab = [
  {
    icon: TbPigMoney,
    text: "Deposit",
    link: "deposit",
    // hidden: isCredit,
  },
  // {
  //   icon: BiMoneyWithdraw,
  //   text: "Withdraw",
  //   link: "withdraw",
  //   hidden: isCredit,
  // },
  {
    iconImage: (
      <Image
        h="25px"
        w="25px"
        src={`${COMMON_WEB_PATH}/happy_paradise/v3-layout-icon/market-icon.png`}
      />
    ),
    text: "Market-store",
    customLink: "/market-store",
    // hidden: window?.open_store == 0,
  },
  {
    icon: TbReportAnalytics,
    text: "Bet Record",
    link: "betRecord",
  },
  {
    icon: MdHistory,
    text: "Transaction",
    link: "transaction",
  },
  {
    icon: PiChartLineUpBold,
    text: "Rebate",
    link: "my-rebate",
    auth: true,
    hidden: !isCredit,
  },
];

const CommonSelectTabV3 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const i18n_profile = (key) => t(`profile.${key}`);

  const cardBg = useColorModeValue("brand.500", "brand.500");
  const cardHoverBg = useColorModeValue("light.200", "dark.300");
  const textColor = useColorModeValue("dark.100", "light.100");

  const location = useLocation();
  const navigate = useNavigate();

  const currentPath = useCallback(
    (target = "") => {
      const splitPathname = location.pathname.split("/");

      if (target) {
        return splitPathname.some((item) => item === target);
      }
    },
    [location.pathname]
  );
  return (
    <ChakraBox
      {...gameListFromBottom}
      gap="5px"
      px="20px"
      py="15px"
      as="section"
      bgGradient={"linear(to-b, v3-profile.banner-from,  v3-profile.banner-to)"}
      borderBottom="5px solid"
      borderColor="v3-profile.banner-border"
      borderRadius={`20px`}
    >
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Flex mb="20px" color="brand.900" direction={`column`} gap="0px">
          <Text fontSize="md" fontWeight={`500`}>
            {i18n_profile("Balance")}
          </Text>
          <BalanceV3
            balanceStyle={{
              color: "gray.900",
            }}
            fontSize="2xl"
          />
        </Flex>
        <Button
          rounded={"30px"}
          leftIcon={<GoPlus />}
          borderBottom="2px solid"
          borderBottomColor="brand.900"
          borderTop="1px solid"
          borderTopColor="brand.600"
          size="md"
          variant={"brandGradientPrimary"}
          type="button"
          onClick={() => {
            navigate("deposit");
          }}
        >
          DEPOSIT
        </Button>
      </Flex>

      <Grid
        gridTemplateColumns={`repeat(4, minmax(0, 1fr))`}
        gap="10px"
        overflow="hidden"
      >
        {common_tab
          ?.filter((item) => !item.hidden)
          ?.map((item) => {
            const active = currentPath(item.link);
            const { iconImage, customLink } = item || {};
            return (
              <Link
                className="overflow-hidden"
                to={customLink || `../profile/${item.link}`}
              >
                <Flex
                  role="group"
                  direction={`column`}
                  alignItems={`center`}
                  gap="5px"
                >
                  <Flex
                    direction={`column`}
                    gap="3px"
                    justifyContent={`center`}
                    alignItems={`center`}
                    // _groupHover={{
                    //   bg: cardHoverBg,
                    // }}
                    w="45px"
                    h="45px"
                    borderRadius={`full`}
                    bgGradient={"linear(to-b, brand.600, brand.900)"}
                    py="7px"
                    className="transition duration-100"
                  >
                    {iconImage || (
                      <Icon
                        as={item.icon}
                        fontSize={`2xl`}
                        color={`white`}
                        // _groupHover={{
                        //   color: "brand.500",
                        // }}
                      />
                    )}
                  </Flex>
                  <Text
                    fontSize="xs"
                    letterSpacing={`tight`}
                    // _groupHover={{
                    //   fontWeight: `700`,
                    // }}
                    w="100%"
                    textAlign={`center`}
                    fontWeight={active ? `700` : "400"}
                    color="black"
                  >
                    {i18n(item.text?.toLowerCase())}
                  </Text>
                </Flex>
              </Link>
            );
          })}
      </Grid>
    </ChakraBox>
  );
};

export default CommonSelectTabV3;
