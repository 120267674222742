import { Avatar, Box, Flex, Image, Text } from "@chakra-ui/react";
import { COMMON_WEB_PATH } from "../../../../constant";
import { formatNumber } from "../../../../utils/formatNumber";
import ToggleFavorite from "../../../Favorite/toggleFavorite";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useCheckLogin from "../../../../hook/useCheckLogin";
import { useTranslation } from "react-i18next";
import { notice } from "../../../../redux/action/action";
import useCurrencySymbol from "../../../../hook/useCurrencySymbol";
import useDeviceType from "../../../../hook/useDeviceType";
import { thisImageNeedDark } from "../../../../utils/thisImageNeedDark";

const JackpotRankingCard = ({
  props,
  reverse = false,
  showPlatformAvatar = false,
}) => {
  const { t } = useTranslation();
  const i18n_common = (key) => t(`common:${key}`);

  const { platform, game_code, memId, profit, game_uid } = props || {};

  const symbol = useCurrencySymbol();

  const { isLogin } = useCheckLogin();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const isMobile = useDeviceType() === "Mobile";

  const handleEnterGame = async (gameItemProps) => {
    const { platform, game_name_en, game_uid } = gameItemProps || {};
    if (!isLogin) {
      dispatch(
        notice({
          title: i18n_common("Please login first"),
          type: "error",
        })
      );
      navigate("signin");
      return;
    }

    if (isMobile) {
      navigate(`game-confirm/${game_name_en}/${platform}/${game_uid}`);
    } else {
      navigate(`/gamebox/${game_name_en}/${platform}/${game_uid}`);
    }
  };

  return (
    <Flex
      w="100%"
      justifyContent={`space-between`}
      alignItems={`center`}
      direction={reverse ? "row-reverse" : ""}
      // h="20px"
      px="3px"
    >
      <Box>
        <Flex
          gap="5px"
          alignItems={`center`}
          direction={reverse ? "row-reverse" : ""}
        >
          <Box mr={showPlatformAvatar ? "25px" : ""} position="relative">
            <Image
              maxW="18px"
              maxH="18px"
              minW="18px"
              minH="18px"
              border="1px solid"
              borderColor="brand.500"
              borderRadius={`5px`}
              src={`${COMMON_WEB_PATH}/game-icon-v2/en/${platform}/${game_code}.webp`}
            />
            {showPlatformAvatar ? (
              <Box
                name=""
                position="absolute"
                right="-25px"
                top="0px"
                w="35px"
                h="35px"
                borderRadius={`full`}
                display="flex"
                alignItems={`center`}
                justifyContent={`center`}
                border="1px solid"
                borderColor="brand.500"
                bg="leaderBoard.avatar"
                p="3px"
                overflow="hidden"
              >
                <Image
                  objectFit={`cover`}
                  width={`80%`}
                  src={`${COMMON_WEB_PATH}/platformIcon/${thisImageNeedDark(
                    platform,
                    true
                  )}.png`}
                />
              </Box>
            ) : null}
          </Box>
        </Flex>
      </Box>
      <Box>
        <Flex
          direction={`column`}
          alignItems={reverse ? `flex-start` : `flex-end`}
          gap="0px"
        >
          <Flex
            direction={`column`}
            alignItems={reverse ? `flex-end` : `flex-start`}
            gap="0px"
          >
            <Text className="text-overflow" fontSize="8px">
              {memId?.slice(0, 3) + "***"}
            </Text>
          </Flex>
          <Box
            bgGradient={`linear(to-b, brand.100, brand.500)`}
            borderRadius={`30px`}
            px="5px"
            py="1px"
            fontSize="4px"
            color="black"
            onClick={() => {
              handleEnterGame(props);
            }}
          >
            GO
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default JackpotRankingCard;
