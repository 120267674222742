import {
  AbsoluteCenter,
  Box,
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { FaFaceSadTear } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import { formatNumber } from "../../../utils/formatNumber";
import { useTranslation } from "react-i18next";

const PointNotEnoughModal = ({ needMoney, isOpen, onClose }) => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`market.${key}`, { ...props });

  return (
    <>
      <Modal isCentered size={"xs"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          py="20px"
          borderBottom={"7px solid"}
          borderColor="#95690E"
          bgGradient={"linear(to-b,#FCC85A,#C28A17)"}
          borderRadius={"15px"}
          position="relative"
        >
          <Box
            zIndex={1}
            position="absolute"
            top="-10px"
            right="-10px"
            onClick={onClose}
          >
            <Box
              w="46px"
              h="46px"
              borderRadius={"full"}
              bgGradient={"linear(to-r,brand.500,brand.900)"}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
              cursor={"pointer"}
            >
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
                bgGradient={"linear(to-r,red.600,red.700)"}
                borderRadius={"full"}
                border="1px solid"
                borderColor="leaderBoard.v3-border"
              >
                <Icon as={IoClose} color="light.100" fontSize="32px" />
              </Box>
            </Box>
          </Box>
          <ModalBody>
            <Box
              borderRadius={"25px"}
              bg="#FDFCFA"
              borderBottom="5px solid"
              borderColor="#FCC75A"
              px="10px"
              display="flex"
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              gap="10px"
              py="30px"
            >
              <Box>
                <Icon
                  as={FaFaceSadTear}
                  color="v3-second-brand.500"
                  fontSize="50px"
                />
              </Box>
              <Box>
                <Text color="black">{i18n("point_not_enough")}</Text>
              </Box>
              <Box fontWeight={"bold"} fontSize="30px" color="#CC2E2F">
                <Text>{formatNumber(needMoney, { point: false })}</Text>
              </Box>
            </Box>
            <AbsoluteCenter top="105%">
              <Button
                as={Link}
                variant={"secondBrandPrimary"}
                type="button"
                to={"/slots"}
                borderRadius={"20px"}
                py="25px"
                px="35px"
                fontWeight={"900"}
                fontSize="20px"
                // borderX="2px solid"
                borderTop="2px solid"
                // borderBottom="5px solid"
                borderColor="v3-second-brand.200"
                outline="2px solid"
                outlineColor={"v3-second-brand.900"}
                position="relative"
                overflow="hidden"
                boxShadow="0px 4px 10px black"
              >
                <Box
                  bg="v3-second-brand.500"
                  position="absolute"
                  w="120%"
                  h="200%"
                  top="-145%"
                  borderRadius={"full"}
                ></Box>
                <Text position="relative" zIndex={1}>
                  {i18n("forward_to_slot")}
                </Text>
              </Button>
            </AbsoluteCenter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PointNotEnoughModal;
