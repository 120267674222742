import i18next from "i18next";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { languageMapping } from "../i18n/config";

const useProcessInboxList = () => {
  const inboxList = useSelector((state) => state.isInboxList);

  const process_inbox_list = useMemo(() => {
    return inboxList.map((item) => {
      const { internal_notifications_language, ...otherProps } = item;

      const find_language =
        internal_notifications_language?.find((item) => {
          return item.language === languageMapping(i18next.language);
        }) || internal_notifications_language[0];

      return {
        ...otherProps,
        ...find_language,
      };
    });
  }, [inboxList, i18next.language]);

  return process_inbox_list;
};

export default useProcessInboxList;
