import { useDispatch, useSelector } from "react-redux";
import CommonBorder from "../BorderComponent/CommonBorder";
import { appName } from "../../config";
import { useTranslation } from "react-i18next";
import usePackageVersion from "../../hook/usePackageVersion";
import {
  Box,
  Flex,
  Image,
  useColorMode,
  useColorModeValue,
  Divider,
  Text,
} from "@chakra-ui/react";
import { COMMON_WEB_PATH } from "../../constant";
import { thisImageNeedDark } from "../../utils/thisImageNeedDark";
import TermAndInfoNew from "../Footer/termAndInfo_new";
import dayjs from "dayjs";

const SponsorFooter = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`footer.${key}`, { ...props });

  const versionCode = usePackageVersion();

  const { colorMode } = useColorMode();

  const footerBg = useColorModeValue("light.100", "#BE5502");
  const textColor = useColorModeValue("special.1", "light.100");

  const gameList = useSelector((state) => Object.keys(state.isGameList));
  const dispatch = useDispatch();

  return (
    <Box
      as="footer"
      px={{
        base: "20px",
        md: "40px",
      }}
      py="80px"
      pt="20px"
      bg={footerBg}
      mt="20px"
      position="relative"
      borderTopRadius={`common`}
    >
      {" "}
      <Box
        as="section"
        display="flex"
        flexWrap={`no-wrap`}
        justifyContent={{
          md: `center`,
        }}
        w="100%"
        overflowX="scroll"
        alignItems={`center`}
        gap="5px"
        className="no-scrollbar"
      >
        {gameList.map((item, index) => {
          return (
            <Box
              w={{
                md: "100px",
              }}
              minW="55px"
              cursor={`pointer`}
              key={index}
              className="grayscale-0 hover:grayscale-0 transition duration-300"
            >
              <Image
                w="55px"
                src={`${COMMON_WEB_PATH}/platformIcon/${thisImageNeedDark(
                  item,
                  colorMode === "dark"
                )}.png`}
                alt={item}
              />
            </Box>
          );
        })}
      </Box>
      {/* <Flex alignItems={`center`} justifyContent={`center`} mb="20px">
        <IconCircleWrapper
          divProps={{
            onClick: () => dispatch(openLanguagePop()),
          }}
          className={`hidden md:flex min-h-[38px] min-w-[38px]`}
        >
          <MdOutlineLanguage className="text-xl" />
        </IconCircleWrapper>
      </Flex> */}
      <Divider
        sx={{
          borderColor: "rgba(0,0,0,.85)",
          borderBottom: "1px solid hsla(0,0%,100%,.1)",
          borderTop: "1px solid rgba(34,36,38,.15)",
        }}
      />
      {/* <TermAndInfo /> */}
      <TermAndInfoNew />
      <Divider
        sx={{
          borderColor: "rgba(0,0,0,.85)",
          borderBottom: "1px solid hsla(0,0%,100%,.1)",
          borderTop: "1px solid rgba(34,36,38,.15)",
        }}
      />
      <Flex
        my="10px"
        direction={`column`}
        alignItems={`center`}
        justifyContent={`center`}
      >
        <Text textAlign={`center`} color={textColor} fontSize="md">
          {appName} {i18n("introduce")}
        </Text>
        <Text fontSize="sm" color={textColor}>
          {versionCode}
        </Text>
        <Text fontWeight={`bold`} textAlign={`center`} color="brand.500">
          {i18n("copyRight", { year: dayjs().year() })} {appName}.{" "}
          {i18n("allRight")}
        </Text>
      </Flex>
    </Box>
  );
};

export default SponsorFooter;
