import React, { useEffect, useState } from "react";
import { getStoreProductList } from "../../../api/getApi";
import i18next from "i18next";
import { languageMapping } from "../../../i18n/config";
import { waitTime } from "../../../utils/waitTime";
import { useParams } from "react-router-dom";

const useStoreProduct = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const { marketType } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      await setLoading(true);
      await getStoreProductList({}).then((data) => {
        const process_data = data?.map((item) => {
          const { product_type } = item;
          const product_type_config = JSON.parse(
            product_type?.product_type_name
          );
          const product_config =
            product_type_config?.find(
              (child) => child.language === languageMapping(i18next.language)
            ) || product_type_config?.[0];
          const default_config = product_type_config?.[0];
          return {
            ...item,
            product_config: {
              ...product_type,
              ...product_config,
            },
            default_config,
            image_url: `${process.env.REACT_APP_I18N_PATH}/product/product_${item.uid}_1.png`,
          };
        });
        const filter_data = process_data?.filter((item) => {
          if (marketType) {
            return (
              item?.default_config?.product_type_name?.replace(/\s/g, "") ===
              marketType
            );
          } else {
            return item;
          }
        });
        setList(filter_data);
      });
      await waitTime(1000);
      await setLoading(false);
    };
    fetchData();
  }, [i18next.language, marketType]);

  return { store_product_list: list, store_product_list_loading: loading };
};

export default useStoreProduct;
