import React, { useMemo } from "react";
import CloseDetailPage from "../../../components/CloseComponent/CloseDetailPage";
import { HiChevronLeft } from "react-icons/hi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CloseButton } from "@chakra-ui/react";

const MarketCloseDetailPage = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { product_config } = props || {};

  const prev_location = useMemo(() => {
    if (location.pathname) {
      const split = location.pathname.split("/");
      if (split.some((item) => item === "detail")) {
        return `/market-store/detail/${split[3]}`;
      } else {
        return `/market-store`;
      }
    }
  }, [location.pathname]);

  return (
    <CloseDetailPage
      className={"!bg-[#FDCA62] !mb-0"}
      title={product_config?.product_name}
      hasPrev={
        <HiChevronLeft
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />
      }
      customClose={<CloseButton as={Link} to={prev_location} size="lg" />}
    />
  );
};

export default MarketCloseDetailPage;
