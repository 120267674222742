import React, { Children } from "react";
import useProductDetail from "../hooks/useProductDetail";
import { Outlet } from "react-router-dom";

const MarketItemWrapper = ({ children }) => {
  const { product_detail, loading } = useProductDetail();

  return (
    <>
      <Outlet
        context={{
          product_detail: product_detail,
          loading: loading,
        }}
      />
    </>
  );
};

export default MarketItemWrapper;
