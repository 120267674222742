import React, { useState } from "react";
import LazyImage from "../Loading/lazyImage";
import {
  AbsoluteCenter,
  Box,
  Flex,
  Heading,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { COMMON_WEB_PATH } from "../../constant";

const PROMOTIONITEM = ({ customWidth, itemClass, originProps }) => {
  const textColor = useColorModeValue("dark.200", "light.100");

  const bgColor = useColorModeValue("light.100", "dark.100");

  const { uid, main_title, sub_title, error_url, url } = originProps || {};

  return (
    <Link to={`/promotion/detail/${uid}`}>
      <Box
        height={{
          lg: `185px`,
          base: "150px",
        }}
        mb={`5px`}
        minWidth={`110px`}
        position="relative"
        cursor={`pointer`}
        overflow={`hidden`}
        key={uid}
        bgGradient="linear(to-b,#FFDD85,#FA9114)"
        borderRadius={`15px`}
        borderBottom={`3.5px solid`}
        borderBottomColor="#D04F16"
        p="3.5px"
        className={`${customWidth} ${itemClass}`}
        // style={{ backgroundColor: specialColor }}
      >
        <Box overflow="hidden" h="100%" w="100%" position="relative">
          <LazyImage
            isPromotion
            className="object-cover rounded-[15px] h-full w-full group-hover:scale-[1.05] transition duration-300"
            src={url}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = error_url;
            }}
          />

          <Box
            position={`absolute`}
            bottom={`0px`}
            left={`0px`}
            width={`100%`}
            height={`66px`}
            color={textColor}
            borderBottomRadius={"15px"}
            className="promotion-item-bg"
          >
            <Flex py="12px" px="16px" direction={`column`} gap="5px">
              <Heading
                className="text-overflow"
                as="h4"
                size="sm"
                my="0px"
                fontWeight={`semibold`}
              >
                {main_title}
              </Heading>
              <Heading as="h5" size="xs" fontWeight={`normal`} my="0px">
                {sub_title}
              </Heading>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export default PROMOTIONITEM;
