import { AbsoluteCenter, Box, Flex, Image, Link, Text } from "@chakra-ui/react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import i18next from "i18next";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useMatches, useParams } from "react-router-dom";
import { routeMapping } from "./utils/routeMapping";
import { COMMON_ALT, COMMON_WEB_PATH } from "../../../constant";
import { gameTypeColor } from "./utils/gameTypeColor";
import { exceptGameType } from "./utils/exceptGameType";
import PromoIcon from "../../floatButton/promoIcon";

const GameTypeSelector = ({ gameMainTypeFromProps } = {}) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const { gameType, gameList } = isHandleAllGame || {};

  const splideRef = useRef();

  const location = useLocation();

  const { pathname } = location;

  //   const getGameType = useMemo(() => {
  //     return pathname.split("/")[1];
  //   }, [pathname]);
  const { gameMainType } = useParams();

  const gameSubTypeList = useMemo(() => {
    if (gameType) {
      const combileType = [...gameType];
      const convertType = combileType.map((item) => {
        return routeMapping(item)?.link;
      });
      return [
        ...combileType.map((item, index) => {
          const { link } = routeMapping(item);
          const current_game_type = routeMapping(item)?.origin;
          const platform_list = Object?.keys(gameList[current_game_type] || {});

          return platform_list?.length == 0 && !exceptGameType(item) ? null : (
            <SplideSlide key={index}>
              <Link
                as={(props) => <NavLink {...props} />}
                to={`${link}`}
                _hover={{
                  textDecoration: "none",
                }}
              >
                {(props) => {
                  return (
                    <Box position={`relative`}>
                      {/* {props.isActive && (
                        <Box
                          position={"absolute"}
                          top="4px"
                          left="50%"
                          w="0"
                          h="0"
                          borderStyle={"solid"}
                          borderWidth={"7.5px 5px 0 5px"}
                          sx={{
                            borderColor:
                              "#CC9D35 transparent transparent transparent",
                          }}
                          zIndex={10}
                          transform="translate(-50%,-50%) rotate(0deg)"
                        ></Box>
                      )} */}
                      <Flex
                        bg={props.isActive ? "brand.900" : ""}
                        direction={`column`}
                        alignItems={`center`}
                        overflow={`hidden`}
                        position={`relative`}
                        borderRadius={`15px`}
                        color={`white`}
                        height={`70px`}
                        gap={`3px`}
                        justifyContent={`center`}
                        borderBottom="4px solid"
                        borderColor="brand.700"
                        p="1px"
                      >
                        {/* <AbsoluteCenter w={`100%`} h="100%">
                          <Image
                            w="100%"
                            h="100%"
                            src={`${COMMON_WEB_PATH}/b69-home/game-type-icon/${item}.svg`}
                          />
                        </AbsoluteCenter> */}
                        <Image
                          w="31px"
                          h="31px"
                          position="relative"
                          src={`${COMMON_WEB_PATH}/b69-home/game-type-icon-v3/${item}_icon${
                            props.isActive ? "_active" : ""
                          }.svg`}
                        />
                        <Text
                          fontWeight={`600`}
                          fontSize={"12px"}
                          position="relative"
                        >
                          {i18n(item)}
                        </Text>
                      </Flex>
                    </Box>
                  );
                }}
              </Link>
            </SplideSlide>
          );
        }),
      ];
    }
  }, [gameType, i18next.language, gameList]);

  const findCurrentGameTypeIndex = useMemo(() => {
    const mainType = gameMainTypeFromProps || gameMainType;

    const processGameType = gameType?.map((item) => {
      const textMapping = routeMapping(item);

      return textMapping?.link.replace("/", "");
    });

    return processGameType?.indexOf(mainType);
  }, [gameType, gameMainType, gameMainTypeFromProps]);

  const splideOptions = useMemo(() => {
    return {
      start: findCurrentGameTypeIndex,
      pagination: false,
      arrows: false,
      drag: "free",
      direction: "ttb",
      height: "280px",
      // perPage: 5,
      // perMove: 1,
      gap: 0,
      lazyLoad: "nearby",
      fixedWidth: "70px",
      dragMinThreshold: {
        mouse: 0,
        touch: 10,
      },
      breakpoints: {
        640: {
          fixedHeight: "70px",
          height: "280px",
        },
      },
    };
  }, [findCurrentGameTypeIndex]);

  useEffect(() => {
    if (splideRef.current) {
      const mainType = gameMainTypeFromProps || gameMainType;
      const splide = splideRef.current.splide;
      const processGameType = gameType?.map((item) => {
        const textMapping = routeMapping(item);
        return textMapping?.link.replace("/", "");
      });
      splide.go(processGameType?.indexOf(mainType));
    }
  }, [gameType, gameMainType, gameMainTypeFromProps]);

  return (
    <Flex
      pt={window.roulettes_activity == 1 ? "60px" : "5px"}
      position="relative"
      flexDirection="column"
      alignItems={"center"}
    >
      {window.roulettes_activity == 1 ? (
        <Box
          position={"absolute"}
          top="-40px"
          left="50%"
          transform={"translate(-50%,50%)"}
          as={NavLink}
          to={"n-roulette"}
        >
          <Flex
            alignItems={`center`}
            justifyContent={`center`}
            borderRadius={`10px`}
            width="70px"
            height="70px"
            // bg={iconBg}
          >
            <Image
              src={`${COMMON_WEB_PATH}/happy_paradise/roulette.gif`}
              cursor={`pointer`}
              maxWidth={`70px`}
              borderRadius={`common`}
              alt={COMMON_ALT}
            />
          </Flex>
        </Box>
      ) : null}
      <Box
        borderRadius={"common"}
        overflow="hidden"
        bgGradient={"linear(to-b,brand.500,brand.600,brand.300,brand.700)"}
        pt="3px"
        position="sticky"
        top={{
          base: "65px",
          md: "10px",
        }}
        zIndex={1}
      >
        <Box
          position={"absolute"}
          top="-20px"
          left="-10px"
          w="140%"
          h="140%"
          bgSize="100% 100%"
          bgRepeat={"no-repeat"}
          bgImage={`${COMMON_WEB_PATH}/happy_paradise/light.png`}
        ></Box>
        <Splide
          ref={splideRef}
          options={{
            start: findCurrentGameTypeIndex,
            pagination: false,
            arrows: false,
            drag: "free",
            direction: "ttb",
            // perPage: 5,
            // perMove: 1,
            gap: 0,
            lazyLoad: "nearby",
            fixedWidth: "70px",
            height: "290px",
            dragMinThreshold: {
              mouse: 0,
              touch: 10,
            },
            breakpoints: {
              mediaQuery: "min",
              640: {
                fixedHeight: "70px",
                height: "290px",
              },
              5000: {
                fixedHeight: "70px",
                height: "auto",
              },
            },
          }}
        >
          {gameSubTypeList}
        </Splide>
      </Box>
      {/* <PromoIcon
        containerClass={{
          position: "absolute",
          top: "96%",
        }}
      /> */}
    </Flex>
  );
};

export default GameTypeSelector;
