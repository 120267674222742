import { Box, Flex, Text, chakra, shouldForwardProp } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Jackpot from "./Jackpot";
import { isValidMotionProp, motion } from "framer-motion";
import { gameListFromBottom } from "../../../animateConstant";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { COMMON_WEB_PATH } from "../../../constant";
import JackpotNumber from "./components/jackpotNumber";
import useLeaderList from "../LeaderBoard/components/useLeaderList";
import JackpotRanking from "./components/jackpotRanking";
import { getRankingList } from "../../../api/getApi";
import JackpotRankingCard from "./components/jaccpotRankingCard";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { formatNumber } from "../../../utils/formatNumber";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const JackpotNew = ({ gameMainTypeFromProps }) => {
  const { gameMainType } = useParams();

  //   const { odd, even, all } = useLeaderList();
  //   console.log(all);
  const [dataSort, setDataSort] = useState({
    odd: [],
    even: [],
    all: [],
  });
  const { odd, even, all } = dataSort;
  useEffect(() => {
    if (window?.profit_ranking_status == 1) {
      getRankingList().then((data) => {
        const result = data?.data;

        const odd = result?.filter((_, index) => index % 2 == 0);
        const even = result?.filter((_, index) => index % 2 != 0);

        setDataSort({
          odd,
          even,
          all: result,
        });
      });
    }
  }, [window?.profit_ranking_status]);

  const ref = useRef();
  const ref2 = useRef();
  const ref3 = useRef();

  useEffect(() => {
    if (ref?.current) {
      setInterval(() => {
        ref?.current?.splide?.go(">");
        ref2?.current?.splide?.go(">");
        ref3?.current?.splide?.go(">");
      }, 5000);
    }
  }, []);

  return (
    <ChakraBox
      {...gameListFromBottom}
      mt={{
        base: "0px",
        md: "30px",
      }}
    >
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oxygen:wght@300;400;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Box
        w="100%"
        maxW="300px"
        mx="auto"
        h="190px"
        bgSize="cover"
        bgPos="center"
        bgRepeat="no-repeat"
        // bgImage={`${COMMON_WEB_PATH}/happy_paradise/leaderboard.gif`}
        bgImage="https://911win.s3.ap-southeast-1.amazonaws.com/happy_paradise/leaderboard.gif"
        // transform={"scale(1.2)"}
        position={"relative"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        pt="48px"
        gap="9.5px"
      >
        <Box
          w="65%"
          h="41px"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          overflow={"hidden"}
        >
          <JackpotNumber
            style={{
              fontSize: "31px",
              fontWeight: "900",
            }}
          />
        </Box>
        <Box
          w="90%"
          h="65px"
          display={"flex"}
          justifyContent={"space-between"}
          gap="5px"
          overflow="hidden"
        >
          <Box w="100%" h="100%" display={"flex"}>
            <Splide
              ref={ref}
              className="w-full"
              options={{
                //   type: "loop",
                direction: "ttb",
                //   focus: "center",
                height: "65px",
                pagination: false,
                arrows: false,
                // drag: "free",
                autoplay: true,
                perPage: 1,
                perMove: 1,
                gap: "10px",
                lazyLoad: "nearby",
                dragMinThreshold: {
                  mouse: 0,
                  touch: 10,
                },
              }}
            >
              {all?.map((item, index) => {
                const { platform, game_code, memId, profit, game_uid } =
                  item || {};
                return (
                  <SplideSlide key={`${index}-${item?.game_code}`}>
                    <Flex direction="column" gap="9px">
                      <Box
                        pt="5px"
                        w="100%"
                        display="flex"
                        alignItems={"center"}
                        //   h="25px"
                        fontSize="20px"
                        fontWeight={"900"}
                        justifyContent={"center"}
                        fontFamily={'"Oxygen", serif'}
                        //   h="26px"
                      >
                        {formatNumber(profit, { point: false })}
                      </Box>
                      <JackpotRankingCard props={item} />
                    </Flex>
                  </SplideSlide>
                );
              })}
            </Splide>
          </Box>
          <Box pl="3px" w="100%" h="100%" display={"flex"}>
            {" "}
            <Splide
              ref={ref2}
              className="w-full"
              options={{
                //   type: "loop",
                direction: "ttb",
                //   focus: "center",
                height: "65px",
                pagination: false,
                arrows: false,
                // drag: "free",
                autoplay: true,
                perPage: 1,
                perMove: 1,
                gap: "10px",
                lazyLoad: "nearby",
                dragMinThreshold: {
                  mouse: 0,
                  touch: 10,
                },
              }}
            >
              {even?.map((item, index) => {
                const { platform, game_code, memId, profit, game_uid } =
                  item || {};
                return (
                  <SplideSlide key={`${index}-${item?.game_code}`}>
                    <Flex direction="column" gap="9px">
                      <Box
                        pt="5px"
                        w="100%"
                        display="flex"
                        alignItems={"center"}
                        //   h="25px"
                        fontSize="20px"
                        fontWeight={"900"}
                        justifyContent={"center"}
                        fontFamily={'"Oxygen", serif'}
                        //   h="26px"
                      >
                        {formatNumber(profit, { point: false })}
                      </Box>
                      <JackpotRankingCard props={item} />
                    </Flex>
                  </SplideSlide>
                );
              })}{" "}
            </Splide>
          </Box>
          <Box pl="6px" w="100%" h="100%" display={"flex"}>
            {" "}
            <Splide
              ref={ref3}
              className="w-full"
              options={{
                //   type: "loop",
                direction: "ttb",
                //   focus: "center",
                height: "65px",
                pagination: false,
                arrows: false,
                // drag: "free",
                autoplay: true,
                perPage: 1,
                perMove: 1,
                gap: "10px",
                lazyLoad: "nearby",
                dragMinThreshold: {
                  mouse: 0,
                  touch: 10,
                },
              }}
            >
              {odd?.map((item, index) => {
                const { platform, game_code, memId, profit, game_uid } =
                  item || {};
                return (
                  <SplideSlide key={`${index}-${item?.game_code}`}>
                    <Flex direction="column" gap="9px">
                      <Box
                        pt="5px"
                        w="100%"
                        display="flex"
                        alignItems={"center"}
                        //   h="25px"
                        fontSize="20px"
                        fontWeight={"900"}
                        justifyContent={"center"}
                        fontFamily={'"Oxygen", serif'}
                        //   h="26px"
                      >
                        {formatNumber(profit, { point: false })}
                      </Box>
                      <JackpotRankingCard props={item} />
                    </Flex>
                  </SplideSlide>
                );
              })}{" "}
            </Splide>
          </Box>
        </Box>
      </Box>
    </ChakraBox>
  );
};

export default JackpotNew;
