import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import PageAnimateWrapper from "../../components/Animate/pageAnimateWrapper";
import { Box, Button, Icon, Image, Text } from "@chakra-ui/react";
import CopyIcon from "../../components/CopyComponent/CopyIcon";
import MarketCloseDetailPage from "./components/marketCloseDetailPage";
import MarketDetailWrapper from "./components/marketDetailWrapper";
import { useTranslation } from "react-i18next";

const MarketSuccessV3 = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`market.${key}`, { ...props });

  const parentProps = useOutletContext();
  const { product_detail, loading } = parentProps || {};

  const { img_path, product_language, stock, product_point, product_config } =
    product_detail || {};

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <MarketDetailWrapper>
        <MarketCloseDetailPage />

        <Box>
          <PageAnimateWrapper>
            <Box
              display={"flex"}
              flexDirection={"column"}
              py="50px"
              gap="20px"
              px="20px"
            >
              <Box>
                <Image mx="auto" src={img_path} w="125px" objectFit={"cover"} />
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                w="100%"
                color="v3-second-brand.500"
              >
                <Text
                  className="text-overflow"
                  fontSize="26px"
                  fontWeight={"bold"}
                  mb="5px"
                >
                  {i18n("congragulation")}
                </Text>
              </Box>
              {/* <Box
                color="v3-second-brand.500"
                display="flex"
                justifyContent={"center"}
                alignItems={"center"}
                gap="5px"
                fontSize="20px"
                fontWeight={"bold"}
              >
                訂單編號：123456
                <CopyIcon text="123456" />
              </Box> */}
              <Box
                mt="20px"
                display={"flex"}
                flexDirection={"column"}
                gap="10px"
                alignItems={"center"}
              >
                <Text color="v3-second-brand.500">{i18n("advice_hint")}</Text>
                <Button
                  bg="transparent"
                  bgGradient={"linear(to-b,#FC5151,#C91919)"}
                  borderRadius={"30px"}
                  w="100%"
                  size="lg"
                  onClick={() => {
                    navigate("/profile/transaction?tab=market");
                  }}
                  _hover={{
                    bgGradient: "linear(to-b,#FC5151,#C91919)",
                  }}
                  _active={{
                    bgGradient: "linear(to-b,#FC5151,#C91919)",
                  }}
                >
                  {i18n("forward_to_order_management")}
                </Button>
              </Box>
            </Box>
          </PageAnimateWrapper>
        </Box>
      </MarketDetailWrapper>
    </>
  );
};

export default MarketSuccessV3;
