import React, { useEffect, useMemo, useState } from "react";
import PageAnimateWrapper from "../../components/Animate/pageAnimateWrapper";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Image,
  Skeleton,
  SkeletonText,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { COMMON_WEB_PATH } from "../../constant";
import PointNotEnoughModal from "./components/pointNotEnoughModal";
import MarketCloseDetailPage from "./components/marketCloseDetailPage";
import MarketDetailWrapper from "./components/marketDetailWrapper";
import useProductDetail from "./hooks/useProductDetail";
import { formatNumber } from "../../utils/formatNumber";
import useMyBalance from "../../hook/useMyBalance";
import { useTranslation } from "react-i18next";
import ItemPrice from "./components/itemPrice";
import { useDispatch, useSelector } from "react-redux";
import { notice } from "../../redux/action/action";
import MarketWithdrawPwdModal from "./components/marketWithdrawPwdModal";

const MarketItemV3 = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`market.${key}`, { ...props });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const parentProps = useOutletContext();
  const { product_detail, loading } = parentProps || {};

  const { uid } = useParams();

  const navigate = useNavigate();

  const my_balance = useMyBalance();

  const [openWithdrawPwdModal, setOpenWithdrawPwdModal] = useState(false);

  const memberInfo = useSelector((state) => state.isMemberInfo);
  const dispatch = useDispatch();

  const { withdraw_password_set } = memberInfo || {};

  const {
    img_path,
    product_language,
    stock,
    product_point,
    product_config,
    on_sale,
    product_sale_point,
  } = product_detail || {};

  const item_point = useMemo(() => {
    return on_sale == 1 ? product_sale_point : product_point;
  }, [product_point, product_sale_point, on_sale]);

  const handleClicked = () => {
    if (withdraw_password_set == 0) {
      setOpenWithdrawPwdModal(true);
      return;
    }
    const item_point = on_sale == 1 ? product_sale_point : product_point;
    if (my_balance < Number(item_point)) {
      onOpen();
    } else {
      navigate("check-item");
    }
  };

  useEffect(() => {
    localStorage.removeItem("count");

    localStorage.removeItem("recipient");
    localStorage.removeItem("phone");
    localStorage.removeItem("email");

    localStorage.removeItem("shipment_type");
    localStorage.removeItem("address");
    localStorage.removeItem("store_type");
    localStorage.removeItem("store_code");
    localStorage.removeItem("store_name");
    localStorage.removeItem("member_memo");
  }, []);

  return (
    <>
      <MarketDetailWrapper>
        <MarketCloseDetailPage {...product_detail} />

        <Box>
          <PageAnimateWrapper>
            <Box
              display={"flex"}
              flexDirection={"column"}
              py="50px"
              gap="10px"
              px="20px"
            >
              <Box>
                <Skeleton isLoaded={!loading}>
                  <Image
                    mx="auto"
                    src={img_path}
                    w="300px"
                    objectFit={"cover"}
                  />
                </Skeleton>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
                w="100%"
                color="v3-second-brand.500"
              >
                <SkeletonText
                  mt="4"
                  noOfLines={2}
                  spacing="2"
                  skeletonHeight="3"
                  minW={"200px"}
                  isLoaded={!loading}
                >
                  <Text
                    className="text-overflow"
                    fontSize="26px"
                    fontWeight={"bold"}
                  >
                    {product_config?.product_name || "-"}
                  </Text>
                  <Text fontSize="16px" fontWeight={"medium"} mb="10px">
                    {product_config?.short_introduction || "-"}
                  </Text>
                </SkeletonText>

                <Box
                  fontSize="20px"
                  fontWeight={"bold"}
                  display={"flex"}
                  gap="2px"
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Image
                    src={`${COMMON_WEB_PATH}/b69-home/v3-layout-icon/balance_money_icon.svg`}
                    w="20px"
                    h="20px"
                  />
                  <SkeletonText
                    noOfLines={1}
                    spacing="2"
                    skeletonHeight="3"
                    minW={"100px"}
                    isLoaded={!loading}
                  >
                    <ItemPrice
                      on_sale={on_sale}
                      product_sale_point={product_sale_point}
                      product_point={product_point}
                      saleStyle={{
                        bottom: "-20px",
                      }}
                    />
                  </SkeletonText>
                </Box>
              </Box>
              <SkeletonText
                mt="4"
                noOfLines={7}
                spacing="2"
                skeletonHeight="2"
                minW={"200px"}
                isLoaded={!loading}
              >
                <Box
                  color="v3-second-brand.500"
                  display={"flex"}
                  flexDirection={"column"}
                  gap="5px"
                >
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: product_config?.introduction,
                    }}
                  ></Box>
                </Box>
              </SkeletonText>
              <Box display={"flex"}>
                <Box
                  display="flex"
                  borderRadius={"10px"}
                  py="2.5px"
                  px="10px"
                  fontWeight={"bold"}
                  bg="green.500"
                  fontSize="14px"
                >
                  {i18n("stock")}：{stock}
                </Box>
              </Box>
              <Box mt="20px">
                <Button
                  bg="transparent"
                  bgGradient={"linear(to-b,#FC5151,#C91919)"}
                  borderRadius={"30px"}
                  w="100%"
                  size="lg"
                  onClick={handleClicked}
                  _hover={{
                    bgGradient: "linear(to-b,#FC5151,#C91919)",
                  }}
                  _active={{
                    bgGradient: "linear(to-b,#FC5151,#C91919)",
                  }}
                >
                  {i18n("exchange_now")}
                </Button>
              </Box>
            </Box>
          </PageAnimateWrapper>
        </Box>
      </MarketDetailWrapper>
      <PointNotEnoughModal
        needMoney={Number(item_point) - Number(my_balance)}
        isOpen={isOpen}
        onClose={onClose}
      />
      <MarketWithdrawPwdModal
        isOpen={openWithdrawPwdModal}
        onClose={() => {
          setOpenWithdrawPwdModal(false);
        }}
      />
    </>
  );
};

export default MarketItemV3;
