import { useEffect, useState } from "react";
import { getInboxList } from "../../../api/getApi";
import NotFound from "../../../components/ProgressBlock/NotFound";
import InboxItem from "../InboxItem";
import LoadingBlock from "../../../components/Loading/LoadingBlock";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import LoadingBetRecord from "../../../components/Loading/LoadingBetRecord";
import { Flex } from "@chakra-ui/react";
import { storeInboxList } from "../../../redux/action/member/memberAction";
import useProcessInboxList from "../../../hook/useProcessInboxList";

const InboxList = ({ isInbox }) => {
  const [apiCalling, setApiCalling] = useState(true);

  const location = useLocation();

  const inboxList = useProcessInboxList();
  const dispatch = useDispatch();

  useEffect(() => {
    setApiCalling(true);
    getInboxList()
      .then((data) => {
        console.log(data);

        dispatch(storeInboxList(data?.data));
      })
      .finally(() => {
        setApiCalling(false);
      });
  }, [location.pathname]);

  return (
    <Flex direction={`column`} gap="10px">
      <LoadingBetRecord commonLoading={apiCalling}>
        {inboxList?.length === 0 ? (
          <NotFound />
        ) : (
          inboxList?.map((item, index) => {
            return <InboxItem isInbox key={index} data={item} />;
          })
        )}
      </LoadingBetRecord>
    </Flex>
  );
};

export default InboxList;
