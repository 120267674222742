import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getStoreProductList } from "../../../api/getApi";
import i18next from "i18next";
import dayjs from "dayjs";
import { languageMapping } from "../../../i18n/config";

const useProductDetail = () => {
  const [productDetail, setProductDetail] = useState({});

  const [loading, setLoading] = useState(true);

  const { uid } = useParams();

  useEffect(() => {
    if (uid) {
      getStoreProductList({
        pathParams: uid,
      }).then((data) => {
        const { product_language } = data;
        const img_path = `${
          process.env.REACT_APP_I18N_PATH
        }/product/product_${uid}_1.png?${dayjs().format("HH:mm")}`;
        const product_config =
          product_language?.find(
            (item) => item.language === languageMapping(i18next.language)
          ) || product_language?.[0];
        const combine = {
          ...data,
          img_path,
          product_config,
        };
        setProductDetail(combine);

        setLoading(false);
      });
    }
  }, [uid]);
  return { product_detail: productDetail, loading: loading };
};

export default useProductDetail;
