import { parseSomething } from "../utils/parseSomething";

export const colorConfig =
  process.env.REACT_APP_SECRET_TYPE === "local"
    ? {
        "happy_paradise-main": {
          leaderBoard: {
            left: "#cf8d00",
            right: "#eda200",
            border: "#b27900",
            avatar: "#ffbb29",
            "v3-border": "#C9D0E9",
          },
          brand: {
            100: "#ffeabd",
            200: "#ffe19f",
            300: "#ffd782",
            400: "#ffce64",
            500: "#ffbb29",
            600: "#ffb20b",
            700: "#eda200",
            800: "#de9700",
            900: "#cf8d00",
            950: "#c08300",
            1000: "#b27900",
          },
          special: {
            1: "#ffa46f",
            gold: "#f8e709",
            "jackpot-from": "#FEF87D",
            "jackpot-to": "#F6EB20",
          },
          "game-item": {
            mask: "rgba(255, 187, 41, 0.4)",
            "text-mask-from": "#ffce64",
          },
          "second-brand": {
            500: "#000000",
            700: "#000000",
          },
          "right-promotion-box": {
            to: "#ffc447",
            from: "#ffce64",
            border: "#ffb20b",
            hover_to: "#ffbb29",
            background: {
              platformBg: "#e3e3e3",
              transactionCard: "#fff",
            },
            hover_from: "#ffc447",
          },
          "v3-second-brand": {
            100: "#aa2424",
            200: "#9e2222",
            300: "#931f1f",
            400: "#881d1d",
            500: "#711818",
            600: "#661616",
            700: "#5a1313",
            800: "#551212",
            900: "#4f1111",
            950: "#491010",
            1000: "#440e0e",
            "500-opacity": "#440e0e",
            "700-opacity": "#390c0c",
          },
          "v3-profile": {
            "banner-from": "#fff3db",
            "banner-to": "#ffe19f",
            "banner-border": "#eda200",
            "banner-title": "#ffd782",
          },
          light: {
            100: "#f1f2f4",
            200: "#ffffff",
            300: "#DDE0E3",
            350: "rgb(170,170,170)",
            400: "#535353",
            500: "#15191C",
            600: "#5f5f5f",
          },
          dark: {
            100: "#532104",
            150: "#1e1400",
            200: "#8C3605",
            250: "#eda200",
            300: "#ffb20b",
            400: "#ffc447",
            500: "#ffbb29",
            600: "#ffce64",
            700: "#ffe19f",
          },
        },
      }
    : parseSomething(window.web_style_config);
