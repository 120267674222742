import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { notice } from "../../../redux/action/action";
import { useTranslation } from "react-i18next";
import { FaUserLock } from "react-icons/fa";

const MarketWithdrawPwdModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`market.${key}`, { ...props });

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSubmit = async () => {
    navigate("/profile/settings/reset-withdraw-password");
  };

  return (
    <>
      {" "}
      <Modal isCentered size={"xs"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          py="20px"
          borderBottom={"7px solid"}
          borderColor="#95690E"
          bgGradient={"linear(to-b,#FCC85A,#C28A17)"}
          borderRadius={"15px"}
          position="relative"
        >
          <Box
            zIndex={1}
            position="absolute"
            top="-10px"
            right="-10px"
            onClick={onClose}
            cursor={"pointer"}
          >
            <Box
              w="46px"
              h="46px"
              borderRadius={"full"}
              bgGradient={"linear(to-r,brand.500,brand.900)"}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
                bgGradient={"linear(to-r,red.600,red.700)"}
                borderRadius={"full"}
                border="1px solid"
                borderColor="leaderBoard.v3-border"
              >
                <Icon as={IoClose} color="light.100" fontSize="32px" />
              </Box>
            </Box>
          </Box>
          <ModalBody>
            <Box
              borderRadius={"25px"}
              bg="#FDFCFA"
              borderBottom="5px solid"
              borderColor="#FCC75A"
              px="10px"
              display="flex"
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              gap="10px"
              py="30px"
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <Icon
                  color="v3-second-brand.500"
                  as={FaUserLock}
                  fontSize="50px"
                />
                <Text fontWeight={"bold"} fontSize="xl" color="#CC2E2F">
                  {i18n("notice_you")}!!
                </Text>
                <Text fontSize="sm" color="black">
                  {i18n("if_you_want_to_exchange_item")}
                </Text>
                <Text fontSize="sm" color="black">
                  {i18n("you_need_to_set_your_withdraw_password")}
                </Text>
              </Box>

              <Box
                display={"flex"}
                alignItems={"flex-start"}
                fontSize="16px"
                w="100%"
                flexDirection={"column"}
                gap="5px"
              ></Box>
            </Box>
            <AbsoluteCenter top="100%">
              <Button
                variant={"secondBrandPrimary"}
                type="button"
                onClick={handleSubmit}
                borderRadius={"20px"}
                py="25px"
                px="35px"
                fontWeight={"900"}
                fontSize="20px"
                // borderX="2px solid"
                borderTop="2px solid"
                // borderBottom="5px solid"
                borderColor="v3-second-brand.200"
                outline="2px solid"
                outlineColor={"v3-second-brand.900"}
                position="relative"
                overflow="hidden"
                boxShadow="0px 4px 10px black"
                isLoading={buttonLoading}
              >
                <Box
                  bg="v3-second-brand.500"
                  position="absolute"
                  w="120%"
                  h="200%"
                  top="-145%"
                  borderRadius={"full"}
                ></Box>
                <Text position="relative" zIndex={1}>
                  {i18n("forward_now")}
                </Text>
              </Button>
            </AbsoluteCenter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MarketWithdrawPwdModal;
