import React, { Fragment, useMemo } from "react";
import Seo from "./seo";
import Carousel from "../../components/Carousel";
import { Box, Flex, Text } from "@chakra-ui/react";
import MarqueeList from "../../components/marquee/marqueeList";
import GameTypeSelector from "../../components/v3Layout/GameTypeSelector/GameTypeSelector";
import SubListSelector from "../../components/v3Layout/SubListSelector/SubListSelector";
import JackpotWrapper from "../../components/v3Layout/Jackpot/JackpotWrapper";
import PlatformSelector from "../../components/v3Layout/PlatformSelector/PlatformSelector";
import CarouselV2 from "../../components/Carousel_v2";
import MarqueeListV2 from "../../components/marquee/marqueeList_v2";
import MobileSearchV2 from "../Search/mobile-search_v2";
import LeaderBoard from "../../components/v3Layout/LeaderBoard/LeaderBoard";
import GameCatalogV2 from "../../components/GameCatalog_v2";
import GameSelector from "../../components/v3Layout/GameSelector/GameSelector";
import { useSelector } from "react-redux";
import { routeMapping } from "../../components/v3Layout/GameTypeSelector/utils/routeMapping";
import CarouselV3 from "../../components/Carousel_v3";
import JackpotNew from "../../components/v3Layout/Jackpot/Jackpot_new";
import useDeviceType from "../../hook/useDeviceType";

const SlotV3 = ({ gameMainTypeFromProps }) => {
  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const { gameType, topGameList, newGameList, gameListByTypeAndSubType } =
    isHandleAllGame;

  const isMobile = useDeviceType() === "Mobile";

  const gameSubTypeCategory = useMemo(() => {
    const mainType = routeMapping(gameMainTypeFromProps)?.origin;

    const getSubTypeCategory = Object?.keys(
      gameListByTypeAndSubType?.[mainType] || {}
    )?.reverse();

    return getSubTypeCategory?.map((item) => {
      return (
        <GameCatalogV2
          observeFalse
          wrapperClassName={`!px-[0px]`}
          list={gameListByTypeAndSubType?.[mainType]?.[item]}
          mainTitle={`${item}`}
          customFixedWidth="120px"
        />
      );
    });
  }, [gameListByTypeAndSubType, gameMainTypeFromProps]);

  return (
    <Fragment>
      <Seo />
      <Box as="section" pt="5px" px="common-y">
        <CarouselV3 />
      </Box>
      <Box as="section" pt="5px" px="common-y">
        <MarqueeListV2 />
      </Box>
      {/* <Box
        mt={{
          md: "5px",
          base: "0px",
        }}
      >
        <MobileSearchV2 listHidden />
      </Box> */}

      <Flex>
        <Box
          py={{
            // md: "home-space",
            base: "0",
          }}
          pl="common-y"
          pr="5px"
        >
          <GameTypeSelector gameMainTypeFromProps={gameMainTypeFromProps} />
        </Box>
        <Box
          overflowY="scroll"
          flex={1}
          // h={{
          //   base: "80vh",
          //   md: "auto",
          // }}
        >
          <Box py="home-space" px="common-y">
            <MobileSearchV2 listHidden />
          </Box>
          <Box pb="home-space" px="common-y">
            <PlatformSelector gameMainTypeFromProps={gameMainTypeFromProps} />
          </Box>
          <Box mb="10px" px="common-y">
            <SubListSelector
              gameMainTypeFromProps={gameMainTypeFromProps}
              forceShowAll
            />
          </Box>
          {isMobile ? null : (
            <Box py="home-space" px="common-y">
              <LeaderBoard />
            </Box>
          )}

          <Box my="home-space" px="common-y">
            {isMobile ? <JackpotNew /> : <JackpotWrapper />}
          </Box>

          {/* <Box px="common-y">
            <GameCatalogV2
              wrapperClassName={`!px-[0px]`}
              list={topGameList}
              mainTitle={`Top`}
              // swiperProps={{
              //   customPerView: "popular-layout",
              // }}
              isHot
              observeFalse
              customFixedWidth="155px"
            />
          </Box>{" "}
          <Box px="common-y">
            <GameCatalogV2
              wrapperClassName={`!px-[0px]`}
              list={newGameList}
              mainTitle={`News`}
              customHotTag={{
                color: "linear(to-r,#9DF87B,#0E9117)",
                text: (
                  <Text as="span" fontSize={`sm`} letterSpacing={`tighter`}>
                    New
                  </Text>
                ),
              }}
              observeFalse
              // swiperProps={{
              //   customPerView: "popular-layout",
              // }}
              isHot
              customFixedWidth="155px"
            />
          </Box> */}
          <Box py="home-space" px="common-y">
            <GameSelector
              hiddenTitle
              gameMainTypeFromProps={gameMainTypeFromProps}
            />
          </Box>
        </Box>
      </Flex>
    </Fragment>
  );
};

export default SlotV3;
